/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import { useLocation } from 'react-router-dom';
import TodosFormModal from '../modals/todos.form.modal';
import styles from './todo-board.module.scss';
import { useTranslation } from 'react-i18next';
import TodosViewBoardCol from './todo-view-board-col';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { completeTodos, updateTodoState } from '../../../store/todos/slice';
import { IconAdd, IconCheck, IconDelete, IconEdit, IconSimplePlus } from '../../../shared/utils/icon';
import TodoCreateCol from '../modals/todo-form-create-col';
import { ColumnCustomContract } from '../../../store/column/type';
import {
	deleteCustomColumn,
	deleteUserColumn,
	updateCustomColumn,
	updateUserColumnTitle,
} from '../../../store/column/slice';
import { updateCurrentUserCol, updateUserCol } from '../../../store/user/slice';

interface Props {
	todosAssignee: TodosContract[];
	h?: string;
	w?: string;
	addTodo?: boolean;
	setAddTodo?: (el: boolean) => void;
}

const TodosViewBoard: React.FC<Props> = ({ todosAssignee, w, h, addTodo, setAddTodo }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { customColumnUser } = useAppSelector((state) => state.column);

	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);
	const [dragTodo, setDragTodo] = React.useState<TodosContract>();
	const [isDraggedOver, setIsDraggedOver] = React.useState<string | undefined>(undefined);
	const dragElement = React.useRef<HTMLDivElement>(null);
	const [heightElement, setHeightElement] = React.useState<number>(0);

	const handleUpdate = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
		setOpenUpdate(!openUpdate);
	};

	const toggleUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const handleCurrentDragElement = () => {
		if (dragElement.current !== null && dragElement.current !== undefined) {
			const { height, y } = dragElement.current.getBoundingClientRect();
			setHeightElement(height);
		}
	};

	React.useEffect(() => {
		if (location.search.includes('?create')) {
			const dullTodo = {
				id: '',
				title: 'new todo',
				text: '',
				state: TodosStates.TODO,
				dueDate: '',
				duration: 0,
				graphUserId: user ? user.graphUserId : '',
				noteId: '',
				assigneeDisplayName: user ? user.email : '',
				assigneeName: user ? user.name : '',
				graphiCalUId: null,
				meetingGraphId: null,
				meetingName: null,
				meetingStartDate: null,
				createdOn: new Date(),
				tags: [],
				archived: false,
				projectId: '',
			};

			const todo = dullTodo as unknown as TodosContract;

			if (todo) {
				handleUpdate(todo);
			}
		} else if (location.search.includes('?view')) {
			const id = location.search.split('/')[1];

			const todo = todosAssignee && todosAssignee.find((todoA) => todoA.id === id);

			if (todo) {
				handleUpdate(todo);
			}
		}
	}, [location]);

	const handleDragStart = (e: React.DragEvent<HTMLDivElement>, todoId: string, todoPreviousState: TodosStates) => {
		const todoElement = document.querySelector(`#todoid${todoId}`);
		const board = document.querySelector('#board');

		if (todoElement && board) {
			const data = {
				todoId: todoId.toString(),
				todoPreviousState,
			};

			let dragClickOffsetX: any;
			let dragClickOffsetY: any;

			const getDragClick = (ev: MouseEvent) => {
				dragClickOffsetX = ev.offsetX;
				dragClickOffsetY = ev.offsetY;
			};

			e.dataTransfer.setData('text/plain', JSON.stringify(data));

			todoElement.classList.add('drag');

			const clone = todoElement.cloneNode(true) as HTMLDivElement;

			clone.style.position = 'absolute';
			clone.style.zIndex = '999';
			clone.style.pointerEvents = 'none';
			clone.style.cursor = 'dragging';

			const updatePosition = (event: MouseEvent) => {
				clone.classList.add('drag-clone');

				const x = event.clientX - dragClickOffsetX;
				const y = event.clientY - dragClickOffsetY;

				clone.style.left = `${x}px`;
				clone.style.top = `${y}px`;
			};

			board.appendChild(clone);

			const handleDragEnd = () => {
				clone.remove();
				todoElement.classList.remove('drag');
				document.removeEventListener('drag', updatePosition);
				document.removeEventListener('dragend', handleDragEnd);
			};

			document.addEventListener('dragstart', getDragClick);
			document.addEventListener('drag', updatePosition);
			document.addEventListener('dragend', handleDragEnd);

			const dragImage = new Image();
			dragImage.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
			e.dataTransfer.setDragImage(dragImage, 0, 0);
		}
	};

	const updateTodoStateOnDrop = (todoId: string, newState: TodosStates, index: number, col?: string) => {
		const body = {
			state: newState,
			index,
			userCol: col ? col : 'none',
		};

		const newBody = {
			todoId,
			stateTodo: newState,
			index,
			userCol: col ? col : null,
		};

		dispatch(updateTodoState(newBody));

		dispatch(completeTodos({ body, todoId }));

		const todoElement = document.querySelector(`#todoid${todoId}`);
		const clone = document.querySelector('.drag-clone');
		if (todoElement) {
			todoElement.classList.remove('drag');
			clone?.remove();
		}
	};

	const handleDrop = (
		e: React.DragEvent<HTMLDivElement>,
		newState: TodosStates,
		index: number,
		onBottomDrag: boolean = false,
		col?: string,
	) => {
		e.preventDefault();
		const storedViewIndex: number = onBottomDrag !== true ? index : index;

		const data = JSON.parse(e.dataTransfer.getData('text'));

		const todoElement = document.querySelector(`#todoid${data.todoId}`);
		const clone = document.querySelector('.drag-clone');

		if (todoElement && clone) {
			todoElement.classList.remove('drag');
			clone.remove();
		}

		updateTodoStateOnDrop(data.todoId, newState, storedViewIndex, col);
		setIsDraggedOver(undefined);
	};

	// const [addTodo, setAddTodo] = React.useState<boolean>(false);
	const [addDoing, setAddDoing] = React.useState<boolean>(false);
	const [addBlocked, setAddBlock] = React.useState<boolean>(false);

	const [add, setAdd] = React.useState(false);
	const handleAddNewTodo = () => {
		setAdd(!add);
	};

	const [addColumn, setAddColumn] = React.useState(false);
	const handleAddNewColumn = () => {
		setAddColumn(!addColumn);
	};

	const handleDeleteCol = (col: ColumnCustomContract) => {
		const todosForTheCol = todosAssignee.filter((todo) => todo.columnUserId === col.id);

		todosForTheCol.forEach((todo) => {
			const newBody = {
				todoId: todo.id,
				stateTodo: todo.state,
				index: todo.orderAssignee ? Number(todo.orderAssignee) : 1,
				userCol: null,
			};

			dispatch(updateTodoState(newBody));
		});

		if (col) {
			dispatch(deleteCustomColumn(col.id)).then(() => {
				dispatch(deleteUserColumn(col.id));
			});
		}
	};

	const [editCol, setEditCol] = React.useState(false);
	const [editColTodo, setEditColTodo] = React.useState(false);
	const [editColDoing, setEditColDoing] = React.useState(false);
	const [editColBlocked, setEditColBlocked] = React.useState(false);
	const [editColDone, setEditColDone] = React.useState(false);

	const [title, setTitle] = React.useState('');

	const handleEditCol = () => {
		setEditCol(!editCol);
	};

	const handleEditColTodo = () => {
		setEditColTodo(!editColTodo);
	};

	const handleEditColDoing = () => {
		setEditColDoing(!editColDoing);
	};

	const handleEditColBlocked = () => {
		setEditColBlocked(!editColBlocked);
	};

	const handleEditColDone = () => {
		setEditColDone(!editColDone);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefTodo = React.useRef<HTMLDivElement>(null);
	const modalRefDoing = React.useRef<HTMLDivElement>(null);
	const modalRefBlocked = React.useRef<HTMLDivElement>(null);
	const modalRefDone = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditCol();
				}, 150);
			}

			if (modalRefTodo.current && !modalRefTodo.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditColTodo();
				}, 150);
			}

			if (modalRefDoing.current && !modalRefDoing.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditColDoing();
				}, 150);
			}

			if (modalRefBlocked.current && !modalRefBlocked.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditColBlocked();
				}, 150);
			}

			if (modalRefDone.current && !modalRefDone.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleEditColDone();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleEditCol, handleEditColTodo, handleEditColDoing, handleEditColBlocked, handleEditColDone]);

	const editTitleCol = (col: ColumnCustomContract) => {
		const body = {
			id: col.id,
			userId: col.userId,
			projectId: col.projectId,
			title: title !== '' ? title : col.title,
			todoState: col.todoState,
		};

		dispatch(updateUserColumnTitle(body));

		dispatch(updateCustomColumn(body)).then(() => {
			handleEditCol();
			setTitle('');
		});
	};

	const editBasedCol = (state: TodosStates) => {
		if (user && title !== '') {
			const body = {
				todoCol: state === TodosStates.TODO ? title : user.todoCol,
				doingCol: state === TodosStates.DOING ? title : user.doingCol,
				blockedCol: state === TodosStates.BLOCKED ? title : user.blockedCol,
				doneCol: state === TodosStates.DONE ? title : user.doneCol,
			};

			dispatch(updateCurrentUserCol(body)).then(() => {
				dispatch(updateUserCol(body));
				setTitle('');
				setEditColTodo(false);
				setEditColDoing(false);
				setEditColBlocked(false);
				setEditColDone(false);
			});
		} else {
			setEditColTodo(false);
			setEditColDoing(false);
			setEditColBlocked(false);
			setEditColDone(false);
		}
	};

	return (
		<div className={styles.overview}>
			<div className={styles.mainTodoBoardPage} id='board' style={{ height: h, width: w }}>
				<div
					className={
						todosAssignee.some((todo) => todo.state === TodosStates.TODO && todo.columnUserId === null)
							? styles.column
							: styles.noData
					}
					onDrop={(e) => {
						if (
							todosAssignee.filter(
								(todo) => todo.state === TodosStates.TODO && todo.columnUserId === null,
							).length === 0
						) {
							handleDrop(e, TodosStates.TODO, 0, false);
						}
					}}
					id={TodosStates.TODO}
					onDragOver={(e) => {
						e.preventDefault();
					}}>
					<div className={styles.flex}>
						{!editColTodo && (
							<div className={styles.flexCol}>
								<p>
									{t(user ? user.todoCol : 'To do')}
									<span className={styles.number}>
										{' '}
										{
											todosAssignee.filter(
												(todo) => todo.state === TodosStates.TODO && todo.columnUserId === null,
											).length
										}
									</span>
								</p>
								<div className={styles.icon} onClick={() => handleEditColTodo()}>
									<IconEdit />
								</div>
							</div>
						)}

						{editColTodo && (
							<div className={styles.inputTitle} ref={modalRefTodo}>
								<input
									type='text'
									defaultValue={t(user ? user.todoCol : 'To Do') as string}
									onChange={(e) => setTitle(e.target.value)}
								/>
								<button onClick={() => editBasedCol(TodosStates.TODO)}>
									<IconCheck />
								</button>
							</div>
						)}
						<div className={styles.iconPlus} onClick={() => setAddTodo && setAddTodo(!addTodo)}>
							<IconSimplePlus />
						</div>
					</div>

					<div className={styles.inCol}>
						<TodosViewBoardCol
							todosAssignee={todosAssignee.filter(
								(todo) => todo.state === TodosStates.TODO && todo.columnUserId === null,
							)}
							col={TodosStates.TODO}
							handleUpdate={handleUpdate}
							handleDragStart={handleDragStart}
							handleDrop={handleDrop}
							dragTodo={dragTodo}
							setDragTodo={setDragTodo}
							isDraggedOver={isDraggedOver}
							setIsDraggedOver={setIsDraggedOver}
							dragElement={dragElement}
							handleCurrentDragElement={handleCurrentDragElement}
							heightElement={heightElement}
							addTodo={addTodo}
							setAddTodo={setAddTodo}
							page='todos'
						/>
					</div>
				</div>
				<div
					className={
						todosAssignee.some((todo) => todo.state === TodosStates.DOING && todo.columnUserId === null)
							? styles.column
							: styles.noData
					}
					onDrop={(e) => {
						if (
							todosAssignee.filter(
								(todo) => todo.state === TodosStates.DOING && todo.columnUserId === null,
							).length === 0
						) {
							handleDrop(e, TodosStates.DOING, 0, false);
						}
					}}
					id={TodosStates.DOING}
					onDragOver={(e) => {
						e.preventDefault();
					}}>
					<div className={styles.flex}>
						{!editColDoing && (
							<div className={styles.flexCol}>
								<p>
									{t(user ? user.doingCol : 'Doing')}
									<span className={styles.number}>
										{' '}
										{
											todosAssignee.filter(
												(todo) =>
													todo.state === TodosStates.DOING && todo.columnUserId === null,
											).length
										}
									</span>
								</p>
								<div className={styles.icon} onClick={() => handleEditColDoing()}>
									<IconEdit />
								</div>
							</div>
						)}

						{editColDoing && (
							<div className={styles.inputTitle} ref={modalRefDoing}>
								<input
									type='text'
									defaultValue={t(user ? user.doingCol : 'Doing') as string}
									onChange={(e) => setTitle(e.target.value)}
								/>
								<button onClick={() => editBasedCol(TodosStates.DOING)}>
									<IconCheck />
								</button>
							</div>
						)}
						<div className={styles.iconPlus} onClick={() => setAddDoing(!addDoing)}>
							<IconSimplePlus />
						</div>
					</div>
					<div className={styles.inCol}>
						<TodosViewBoardCol
							todosAssignee={todosAssignee.filter(
								(todo) => todo.state === TodosStates.DOING && todo.columnUserId === null,
							)}
							col={TodosStates.DOING}
							handleUpdate={handleUpdate}
							handleDragStart={handleDragStart}
							handleDrop={handleDrop}
							dragTodo={dragTodo}
							setDragTodo={setDragTodo}
							isDraggedOver={isDraggedOver}
							setIsDraggedOver={setIsDraggedOver}
							dragElement={dragElement}
							handleCurrentDragElement={handleCurrentDragElement}
							heightElement={heightElement}
							addTodo={addDoing}
							setAddTodo={setAddDoing}
							page='todos'
						/>
					</div>
				</div>
				<div
					className={
						todosAssignee.some((todo) => todo.state === TodosStates.BLOCKED && todo.columnUserId === null)
							? styles.column
							: styles.noData
					}
					onDrop={(e) => {
						if (
							todosAssignee.filter(
								(todo) => todo.state === TodosStates.BLOCKED && todo.columnUserId === null,
							).length === 0
						) {
							handleDrop(e, TodosStates.BLOCKED, 0, false);
						}
					}}
					id={TodosStates.BLOCKED}
					onDragOver={(e) => {
						e.preventDefault();
					}}>
					<div className={styles.flex}>
						{!editColBlocked && (
							<div className={styles.flexCol}>
								<p>
									{t(user ? user.blockedCol : 'Blocked')}
									<span className={styles.number}>
										{' '}
										{
											todosAssignee.filter(
												(todo) =>
													todo.state === TodosStates.BLOCKED && todo.columnUserId === null,
											).length
										}
									</span>
								</p>
								<div className={styles.icon} onClick={() => handleEditColBlocked()}>
									<IconEdit />
								</div>
							</div>
						)}

						{editColBlocked && (
							<div className={styles.inputTitle} ref={modalRefBlocked}>
								<input
									type='text'
									defaultValue={t(user ? user.blockedCol : 'Blocked') as string}
									onChange={(e) => setTitle(e.target.value)}
								/>
								<button onClick={() => editBasedCol(TodosStates.BLOCKED)}>
									<IconCheck />
								</button>
							</div>
						)}
						<div className={styles.iconPlus} onClick={() => setAddBlock(!addBlocked)}>
							<IconSimplePlus />
						</div>
					</div>
					<div className={styles.inCol}>
						<TodosViewBoardCol
							todosAssignee={todosAssignee.filter(
								(todo) => todo.state === TodosStates.BLOCKED && todo.columnUserId === null,
							)}
							col={TodosStates.BLOCKED}
							handleUpdate={handleUpdate}
							handleDragStart={handleDragStart}
							handleDrop={handleDrop}
							dragTodo={dragTodo}
							setDragTodo={setDragTodo}
							isDraggedOver={isDraggedOver}
							setIsDraggedOver={setIsDraggedOver}
							dragElement={dragElement}
							handleCurrentDragElement={handleCurrentDragElement}
							heightElement={heightElement}
							addTodo={addBlocked}
							setAddTodo={setAddBlock}
							page='todos'
						/>
					</div>
				</div>
				<div
					className={
						todosAssignee.some((todo) => todo.state === TodosStates.DONE && todo.columnUserId === null)
							? styles.column
							: styles.noData
					}
					onDrop={(e) => {
						if (
							todosAssignee.filter(
								(todo) => todo.state === TodosStates.DONE && todo.columnUserId === null,
							).length === 0
						) {
							handleDrop(e, TodosStates.DONE, 0, false);
						}
					}}
					id={TodosStates.DONE}
					onDragOver={(e) => {
						e.preventDefault();
					}}>
					{!editColDone && (
						<div className={styles.flexCol}>
							<p>
								{t(user ? user.doneCol : 'Done')}
								<span className={styles.number}>
									{' '}
									{
										todosAssignee.filter(
											(todo) => todo.state === TodosStates.DONE && todo.columnUserId === null,
										).length
									}
								</span>
							</p>
							<div className={styles.icon} onClick={() => handleEditColDone()}>
								<IconEdit />
							</div>
						</div>
					)}

					{editColDone && (
						<div className={styles.inputTitle} ref={modalRefDone}>
							<input
								type='text'
								defaultValue={t(user ? user.doneCol : 'Done') as string}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<button onClick={() => editBasedCol(TodosStates.DONE)}>
								<IconCheck />
							</button>
						</div>
					)}
					<div className={styles.inCol}>
						<TodosViewBoardCol
							todosAssignee={todosAssignee.filter(
								(todo) => todo.state === TodosStates.DONE && todo.columnUserId === null,
							)}
							col={TodosStates.DONE}
							handleUpdate={handleUpdate}
							handleDragStart={handleDragStart}
							handleDrop={handleDrop}
							dragTodo={dragTodo}
							setDragTodo={setDragTodo}
							isDraggedOver={isDraggedOver}
							setIsDraggedOver={setIsDraggedOver}
							dragElement={dragElement}
							handleCurrentDragElement={handleCurrentDragElement}
							heightElement={heightElement}
							page='todos'
						/>
					</div>
				</div>
				{customColumnUser &&
					customColumnUser.map((col) => (
						<div
							key={col.id}
							className={
								todosAssignee.some((todo) => todo.columnUserId === col.id)
									? styles.column
									: styles.noData
							}
							id={col.todoState}
							onDrop={(e) => {
								if (todosAssignee.filter((todo) => todo.columnUserId === col.id).length === 0) {
									handleDrop(e, col.todoState, 0, false, col.id);
								}
							}}
							onDragOver={(e) => {
								e.preventDefault();
							}}>
							{!editCol && (
								<div className={styles.flexCol}>
									<p>
										{col.title}
										<span className={styles.number}>
											{' '}
											{todosAssignee.filter((todo) => todo.columnUserId === col.id).length}
										</span>
									</p>
									<div className={styles.icon} onClick={() => handleEditCol()}>
										<IconEdit />
									</div>
									<div className={styles.icon} onClick={() => handleDeleteCol(col)}>
										<IconDelete />
									</div>
								</div>
							)}
							{editCol && (
								<div className={styles.inputTitle} ref={modalRef}>
									<input
										type='text'
										defaultValue={col.title}
										onChange={(e) => setTitle(e.target.value)}
									/>
									<button onClick={() => editTitleCol(col)}>
										<IconCheck />
									</button>
								</div>
							)}

							<div className={styles.inCol}>
								<TodosViewBoardCol
									col={col.todoState}
									todosAssignee={todosAssignee.filter((todo) => todo.columnUserId === col.id)}
									handleUpdate={handleUpdate}
									handleDragStart={handleDragStart}
									handleDrop={handleDrop}
									dragTodo={dragTodo}
									setDragTodo={setDragTodo}
									isDraggedOver={isDraggedOver}
									setIsDraggedOver={setIsDraggedOver}
									dragElement={dragElement}
									handleCurrentDragElement={handleCurrentDragElement}
									heightElement={heightElement}
									colCustomId={col.id}
									page='todos'
								/>
							</div>
						</div>
					))}
				<div className={styles.column} onClick={() => handleAddNewColumn()}>
					{/* <p>{t('New section')}</p> */}
					<div className={styles.newCol}>
						<IconAdd />
					</div>
				</div>
			</div>
			{openUpdate && todoToUpdate && (
				<TodosFormModal toggle={toggleUpdate} handleToggle={toggleUpdate} todo={todoToUpdate} />
			)}
			{addColumn && <TodoCreateCol handleClose={handleAddNewColumn} />}
		</div>
	);
};

export default TodosViewBoard;
