import { UserLanguage } from '../../store/shared/types';

export const getListTemplate = (language: UserLanguage) => {
	switch (language) {
		case UserLanguage.EN:
			return `
            Human: Analyze the meeting transcript below and generate a structured summary by summarizing the key points discussed in bullet form with a maximum of two levels.
            Identify the main topics, decisions made, actions to be taken, participants, and objectives.
            If relevant, organize the summary into sections such as # Overview, # Topics Discussed, # Decisions, # Upcoming Actions, and # Open Questions.
            Ensure the summary is concise, clear, and ready to be shared with participants.

            Human: Only the section titles (# Overview, # Topics Discussed, # Decisions, # Upcoming Actions, and # Open Questions) should be level one, preceded by a #.

            Human: For the # Topics Discussed section, structure it internally using subtitles starting with ## for each topic covered. Then, elaborate on the key points of each topic in bullet form.
            Human: Avoid duplicating the topics discussed. They should not be repeated multiple times.

            Human: Exclude any topics related to:

            Participants' personal lives.
            Noting participants arriving late or leaving early during the meeting.
            Human: Display the text directly without additional introduction.
        `;

		case UserLanguage.FR:
			return `
            Humain : Analyse le transcript de la réunion ci-dessous et génère un compte rendu structuré en résumant les points clés discutés uniquement sous forme de bullet avec maximum 2 niveaux. 
            Identifie les sujets principaux, les décisions prises, les actions à entreprendre, les participants et les objectifs. 
            Si pertinent, organise le résumé en sections telles que '# Vue d'ensemble', '# Sujets abordés', '# Décisions', '# Actions à venir', et '# Questions ouvertes'. 
            Assure-toi que le compte rendu soit concis, clair, et prêt à être partagé avec les participants.

            Humain: Seules les titres de sections ('# Vue d'ensemble', '# Sujets abordés', '# Décisions', '# Actions à venir', et '# Questions ouvertes') peuvent être de niveau un, précédé d'un "#"

            Humain: Pour la section '# Sujets abordés', structure l'intérieure sous forme de sous titre débutant par "##" pour chaque sujets abordés. Ensuite, exploite les points clés de chaque sujets sous forme de bullet
            Humain: Ne débouble surtout pas les sujet abordés. Ils ne doivent pas être répété à de multiple reprises.

            Humain : Tu éviteras tout sujet concernant :
            - La vie personnelle des participants.
            - Le fait de signaler les participants arrivés en retard ou partis plus tôt lors de la réunion.

            Humain : Affiche directement le texte sans introduction supplémentaire.

        `;

		case UserLanguage.DE:
			return `
            Mensch: Analysiere das untenstehende Sitzungsprotokoll und erstelle eine strukturierte Zusammenfassung, indem du die besprochenen Hauptpunkte in Form von Stichpunkten zusammenfasst, mit maximal zwei Ebenen.
            Identifiziere die Hauptthemen, getroffene Entscheidungen, zu ergreifende Maßnahmen, Teilnehmer und Ziele.
            Falls relevant, organisiere die Zusammenfassung in Abschnitte wie # Überblick, # Besprochene Themen, # Entscheidungen, # Anstehende Maßnahmen und # Offene Fragen.
            Stelle sicher, dass die Zusammenfassung prägnant, klar und bereit zur Weitergabe an die Teilnehmer ist.

            Mensch: Nur die Abschnittstitel (# Überblick, # Besprochene Themen, # Entscheidungen, # Anstehende Maßnahmen und # Offene Fragen) sollen auf der ersten Ebene stehen, eingeleitet mit #.

            Mensch: Für den Abschnitt # Besprochene Themen strukturiere den Inhalt intern mit Untertiteln, die mit ## für jedes besprochene Thema beginnen. Danach führe die Hauptpunkte jedes Themas in Form von Stichpunkten auf.
            Mensch: Vermeide die doppelte Nennung von Themen. Sie dürfen nicht mehrfach wiederholt werden.

            Mensch: Schließe Themen aus, die sich auf Folgendes beziehen:

            Das Privatleben der Teilnehmer.
            Hinweise auf Teilnehmer, die verspätet erschienen oder früher gegangen sind.
            Mensch: Zeige den Text direkt an, ohne zusätzliche Einleitung.
        `;

		case UserLanguage.NL:
			return `
            Mens: Analyseer de onderstaande transcriptie van de vergadering en maak een gestructureerd verslag door de belangrijkste besproken punten samen te vatten in de vorm van opsommingstekens, met maximaal twee niveaus.
            Identificeer de hoofdonderwerpen, genomen beslissingen, uit te voeren acties, deelnemers en doelen.
            Indien relevant, organiseer de samenvatting in secties zoals # Overzicht, # Besproken Onderwerpen, # Beslissingen, # Acties, en # Open Vragen.
            Zorg ervoor dat het verslag beknopt, duidelijk en klaar is om met de deelnemers te worden gedeeld.

            Mens: Alleen de sectietitels (# Overzicht, # Besproken Onderwerpen, # Beslissingen, # Acties en # Open Vragen) mogen op het eerste niveau staan, voorafgegaan door #.

            Mens: Voor de sectie # Besproken Onderwerpen structureer je de inhoud met subtitels die beginnen met ## voor elk besproken onderwerp. Vervolgens vermeld je de belangrijkste punten van elk onderwerp in opsommingstekens.
            Mens: Vermijd dubbele vermelding van onderwerpen. Ze mogen niet meerdere keren worden herhaald.

            Mens: Sluit onderwerpen uit die betrekking hebben op:

            Het privéleven van de deelnemers.
            Vermeldingen van deelnemers die te laat zijn gekomen of eerder zijn vertrokken.
            Mens: Toon de tekst direct zonder extra inleiding.
        `;

		default:
			return `
            Human: Analyze the meeting transcript below and generate a structured summary by summarizing the key points discussed in bullet form with a maximum of two levels.
            Identify the main topics, decisions made, actions to be taken, participants, and objectives.
            If relevant, organize the summary into sections such as # Overview, # Topics Discussed, # Decisions, # Upcoming Actions, and # Open Questions.
            Ensure the summary is concise, clear, and ready to be shared with participants.

            Human: Only the section titles (# Overview, # Topics Discussed, # Decisions, # Upcoming Actions, and # Open Questions) should be level one, preceded by a #.

            Human: For the # Topics Discussed section, structure it internally using subtitles starting with ## for each topic covered. Then, elaborate on the key points of each topic in bullet form.
            Human: Avoid duplicating the topics discussed. They should not be repeated multiple times.

            Human: Exclude any topics related to:

            Participants' personal lives.
            Noting participants arriving late or leaving early during the meeting.
            Human: Display the text directly without additional introduction.
        `;
	}
};

// -------------

export const getQuestionTemplate = (language: UserLanguage, question: string) => {
	switch (language) {
		case UserLanguage.EN:
			return `
            Human: Always provide at least one response to the following question in a structured format:
            Human: ${question}
            Human: Your response must adhere to the following rules:
            - Avoid any topics about:
            - Personal lives of participants
            - Pointing out late participants or early leavers in the meeting
            - Include an introduction to the response.
            - Format your response with clear structure:
            - Use section titles when appropriate.
            - Maintain clarity and conciseness.
        `;

		case UserLanguage.FR:
			return `
            Humain : Fournissez toujours au moins une réponse à la question suivante dans un format structuré :  
            Humain : ${question}  
            Humain : Votre réponse doit respecter les règles suivantes :  
            - Évitez tout sujet concernant :  
            - La vie personnelle des participants  
            - Mentionner les participants en retard ou ceux qui quittent la réunion en avance  
            - Incluez une introduction à la réponse.  
            - Formatez votre réponse de manière claire et structurée :  
            - Utilisez des titres de section lorsque cela est approprié.  
            - Maintenez clarté et concision.

        `;

		case UserLanguage.DE:
			return `
            Mensch: Geben Sie immer mindestens eine Antwort auf die folgende Frage in einem strukturierten Format:  
            Mensch: ${question}  

            Mensch: Ihre Antwort muss die folgenden Regeln befolgen:  
            - Vermeiden Sie alle Themen über:  
            - Das Privatleben der Teilnehmer  
            - Das Ansprechen von zu spät kommenden oder früh gehenden Teilnehmern  
            - Fügen Sie eine Einleitung zu Ihrer Antwort hinzu.  
            - Strukturieren Sie Ihre Antwort klar:  
            - Verwenden Sie bei Bedarf Abschnittsüberschriften.  
            - Achten Sie auf Klarheit und Präzision.  

        `;

		case UserLanguage.NL:
			return `
            Mens: Geef altijd minstens één antwoord op de volgende vraag in een gestructureerd formaat:  
            Mens: ${question}  

            Mens: Uw antwoord moet voldoen aan de volgende regels:  
            - Vermijd elk onderwerp over:  
            - Het persoonlijke leven van de deelnemers  
            - Het benoemen van laatkomers of deelnemers die de vergadering vroeg verlaten  
            - Voeg een introductie toe aan het antwoord.  
            - Structureer uw antwoord duidelijk:  
            - Gebruik sectietitels waar nodig.   
            - Zorg voor duidelijkheid en beknoptheid. 

        `;

		default:
			return `
            Human: Always provide at least one response to the following question in a structured format:
            Human: ${question}
            Human: Your response must adhere to the following rules:
            - Avoid any topics about:
            - Personal lives of participants
            - Pointing out late participants or early leavers in the meeting
            - Include an introduction to the response.
            - Format your response with clear structure:
            - Use section titles when appropriate.
            - Maintain clarity and conciseness.
        `;
	}
};
