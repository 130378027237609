import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../store/hooks';
import styles from './fivedays-companies.module.scss';
import FivedaysCompaniesModal from './fivedays-companies.modal';
import { IconRightChev, IconText } from '../../../shared/utils/icon';
import { isAfter, subDays } from 'date-fns';
import { BillingPlan } from '../../../store/billing/type';
import { getDateFormatL } from '../../../shared/utils/date.util';
import { RequestStatusType } from '../../../store/shared/types';
import Loader from '../../../shared/components/loader/loader.component';

const FivedaysCompaniesComponent = () => {
	const { t } = useTranslation();
	const { companies, companiesRequestStatus } = useAppSelector((state) => state.settings);

	const [openModal, setOpenModal] = React.useState(false);
	const [companyId, setCompanyId] = React.useState('');

	const handleModal = (id: string) => {
		setCompanyId(id);
		setOpenModal(!openModal);
	};

	const sevenDaysAgo = subDays(new Date(), 7);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.listViewContainer}>
					<div className={styles.headTitle} style={{ gridTemplateColumns: `450px repeat(${5}, 1fr) 30px` }}>
						<div className={styles.flexHeader}>
							<div className={styles.title} style={{ color: '#2E4552' }}>
								<IconText /> {t('Company name')}
							</div>
						</div>

						<div className={styles.flexHeader}>
							<div className={styles.title} style={{ color: '#2E4552' }}>
								<IconText /> {t('Number of users')}
							</div>
						</div>

						<div className={styles.flexHeader}>
							<div className={styles.title} style={{ color: '#2E4552' }}>
								<IconText /> {t('Plan')}
							</div>
						</div>

						<div className={styles.flexHeader}>
							<div className={styles.title} style={{ color: '#2E4552' }}>
								<IconText /> {t('Connected Users the past 7 days')}
							</div>
						</div>

						<div className={styles.flexHeader}>
							<div className={styles.title} style={{ color: '#2E4552' }}>
								<IconText /> {t('Number of bots created the past 7 days')}
							</div>
						</div>
					</div>
					<div className={styles.todoRowList}>
						{!companies && companiesRequestStatus.type === RequestStatusType.InProgress && (
							<div style={{ textAlign: 'center', margin: 'auto' }}>
								<Loader />
							</div>
						)}
						{companies &&
							companies.map((company) => (
								<div
									key={company.id}
									style={{ gridTemplateColumns: `450px repeat(${5}, 1fr) 30px` }}
									className={styles.todoRow}>
									<div className={styles.titleTodo}>
										<p className={styles.name}>{company.name}</p>
										<div
											className={styles.titleTodoIcon}
											onClick={(e) => {
												e.stopPropagation();
												handleModal(company.id);
											}}>
											<IconRightChev height='14' width='14' />
										</div>
									</div>
									<div className={styles.titleTodo}>
										<p className={styles.name}>{company.users?.length}</p>
									</div>
									<div className={styles.titleTodo}>
										<p className={styles.name}>{company.billingPlan.plan}</p>
										{company.billingPlan.plan === BillingPlan.TRIAL && (
											<span
												style={{
													color: isAfter(
														new Date(),
														new Date(company.billingPlan.endDateTrial),
													)
														? 'red'
														: 'black',
												}}>
												({getDateFormatL(company.billingPlan.endDateTrial)})
											</span>
										)}
										{company.billingPlan.plan === BillingPlan.PERUSER && (
											<span>
												({company.users?.length} / {company.billingPlan.usersForPlan})
											</span>
										)}
									</div>
									<div className={styles.titleTodo}>
										<p className={styles.name}>
											{company.users?.filter((user) => {
												const lastConnectionDate = new Date(user.lastConnection);
												return lastConnectionDate >= sevenDaysAgo;
											}).length || 0}
										</p>
									</div>
									<div className={styles.titleTodo}>
										<p className={styles.name}>{company.numberBots ? company.numberBots : 0}</p>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			{openModal && <FivedaysCompaniesModal close={handleModal} companyId={companyId} />}
		</>
	);
};

export default FivedaysCompaniesComponent;
