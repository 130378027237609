/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './project-meetings.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { MeetingContract, TagContract } from '../../../../../store/meetings/types';
import { hexToRgb } from '../../../../../shared/utils/hextorgb';
import { MeetingsProjectContract } from '../../../../../store/project/type';
import {
	getDateFormatWithYear,
	getDayPastOneDay,
	getStartOfDay,
	getStartOfDayDate,
	getTimeFormat,
} from '../../../../../shared/utils/date.util';
import { getMeetings, setSelectedMeeting } from '../../../../../store/meetings/slice';
import { RouteSearchParams, RouteUrls } from '../../../../../routes/routes-config';
import { useNavigate } from 'react-router-dom';
import { IconMeetings, IconMeetingsPage, IconTag, IconText } from '../../../../../shared/utils/icon';
import { Avatar } from '../../../../../shared/utils/avatar';
import { PersonaSize } from '@fluentui/react';

interface Props {
	meetings: MeetingsProjectContract[];
}

const DetailsMeetingsTableComponent: React.FC<Props> = ({ meetings }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);

	const navigate = useNavigate();

	// ---

	const [viewAttendees, setViewAttendees] = React.useState<number | undefined>(undefined);

	const handleViewAttendees = (index: number) => {
		setViewAttendees((prev) => (prev === index ? undefined : index));
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [viewTags, setViewTags] = React.useState<number | undefined>(undefined);

	const handleViewTags = (index: number) => {
		setViewTags((prev) => (prev === index ? undefined : index));
	};

	const modalRefTags = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				(viewAttendees !== undefined && modalRef.current && !modalRef.current.contains(event.target as Node)) ||
				(viewTags !== undefined && modalRefTags.current && !modalRefTags.current.contains(event.target as Node))
			) {
				setTimeout(() => {
					setViewAttendees(undefined);
					setViewTags(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [viewAttendees, viewTags]);

	return (
		<>
			<tr className={styles.header}>
				<td>
					<div className={styles.title}>
						<IconText /> {t('Meeting name')}
					</div>
				</td>
				<td>
					<div className={styles.title}>
						<IconMeetingsPage /> {t('Attendees')}
					</div>
				</td>
				<td>
					<div className={styles.title}>
						<IconMeetings /> {t('Date')}
					</div>
				</td>
				<td>
					<div className={styles.title}>
						<IconTag /> {t('tags')}
					</div>
				</td>
			</tr>
			{meetings &&
				meetings.map((meeting: MeetingsProjectContract, index: number) => {
					return (
						<tr
							key={index}
							className={styles.todo}
							onClick={(e) => {
								e.stopPropagation();
								if (user && meeting.meetingAttendees.includes(user.userName)) {
									const startDate = getStartOfDayDate(new Date(meeting.meetingStartDate));
									const endDate = getDayPastOneDay(new Date(meeting.meetingStartDate));
									const dateRange = { startDate, endDate };
									dispatch(getMeetings(dateRange)).then((res) => {
										const meetingsSelect = (res.payload as MeetingContract[]).find(
											(m) => m.iCalUId === meeting.graphiCalUId,
										);
										dispatch(setSelectedMeeting(meetingsSelect));
										const params: any = {};
										params[RouteSearchParams.Date] = getStartOfDay(meeting.meetingStartDate);
										params[RouteSearchParams.MeetingId] = meeting.graphiCalUId;
										navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
									});
								}
							}}
							style={{
								cursor:
									user && meeting.meetingAttendees.includes(user.userName) ? 'pointer' : 'default',
								opacity: user && meeting.meetingAttendees.includes(user.userName) ? '1' : '0.3',
							}}>
							<td>
								<p className={styles.titleMeeting}>{meeting.meetingName}</p>
								{meeting && meeting.meetingEndDate && (
									<p className={styles.hour}>
										{getTimeFormat(meeting.meetingStartDate, meeting.meetingEndDate)}
									</p>
								)}
							</td>

							<td>
								<div className={styles.attendeesFlex}>
									{meeting.meetingAttendees &&
										meeting.meetingAttendees.slice(0, 2).map((attendee: string, indexAt) => {
											return (
												<div key={indexAt}>
													<Avatar
														size={PersonaSize.size32}
														name={attendee}
														mail={attendee}
														index={indexAt}
													/>
												</div>
											);
										})}

									{meeting.meetingAttendees.length > 2 && (
										<div
											style={{ cursor: 'pointer' }}
											onClick={(e) => {
												e.stopPropagation();
												handleViewAttendees(index);
											}}
											className={styles.plus}>
											<div>+ {meeting.meetingAttendees.length - 2}</div>
										</div>
									)}
									{viewAttendees === index && (
										<div className={styles.modalAttendees} ref={modalRef}>
											{meeting.meetingAttendees.map((attendee, newIndex) => (
												<div className={styles.attendee} key={newIndex}>
													<Avatar name={attendee} mail={attendee} index={newIndex} />
													<span>{attendee}</span>
												</div>
											))}
										</div>
									)}
								</div>
							</td>

							<td>
								<p className={styles.dateMeeting}>{getDateFormatWithYear(meeting.meetingStartDate)}</p>
							</td>

							<td>
								<div className={styles.tags}>
									{meeting.tags?.slice(0, 2).map((tag: TagContract, indexTag) => (
										<span
											key={indexTag}
											className={styles.tag}
											style={{
												backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
												border: `1px solid ${tag.color}`,
											}}>
											<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
										</span>
									))}
									{meeting.tags.length > 2 && (
										<div
											className={styles.plus}
											style={{ cursor: 'pointer' }}
											onClick={(e) => {
												handleViewTags(index);
												e.stopPropagation();
											}}>
											<div>+ {meeting.tags.length - 2}</div>
										</div>
									)}
									{viewTags === index && (
										<div className={styles.modalAttendees} ref={modalRefTags}>
											<div className={styles.tags}>
												{meeting.tags
													.slice(1, meeting.tags.length)
													.map((tag: TagContract, indexTagView) => (
														<div
															key={indexTagView}
															className={styles.tag}
															style={{
																backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
																border: `1px solid ${tag.color}`,
															}}>
															<div
																style={{
																	color: tag.color,
																	fontWeight: '600',
																}}>
																{tag.title}
															</div>
														</div>
													))}
											</div>
										</div>
									)}
								</div>
							</td>
						</tr>
					);
				})}
		</>
	);
};

export default DetailsMeetingsTableComponent;
