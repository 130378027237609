export enum ApiUrls {
	// MEETINGS
	GetMeetings = '/meetings',
	GetMeetingsCalendar = '/meetings/calendar',
	GetMeetingsForAWeek = '/meetings/week',
	GetMeeting = '/meetings/{eventId}',
	PatchMeetings = '/meetings/{eventId}',
	PatchMeetingsBodyContent = '/meetings/{eventId}/bodyContent/',
	PatchMeetingsTags = '/meetings/{iCalUId}/tags',
	AddMeetingsFile = '/meetings/{iCalUId}/files',
	DeleteMeetingFile = '/meetings/{eventId}/files/{fileId}',
	UpdateMeetingFile = '/meetings/{eventId}/files/{fileId}',
	CreateMeeting = '/meetings',
	DeleteMeeting = '/meetings/{eventId}',

	GetMeetingsForReccurence = '/meetings/meetings/get/series/recurrence/{seriesId}',

	GetMeetingForSelectOption = 'meetings/meetings/options/get/{filter}',

	DownloadMeetingFile = '/meetings/{iCalUId}/files/download/{fileId}',

	getLastMeetings = '/meetings/last/meetings',

	getMeetingCollab = 'meeting/share/collaborators/{iCalUId}/{mail}',

	getDescriptionforTheMeeting = '/informations/{iCalUId}',

	// USERS

	GetAvatar = '/users/avatar',
	GetAvatarForAttendees = '/users/avatar/{mail}',
	GetAvatarForAttendeesGoogle = '/users/avatar/google/{mail}',
	GetCurrentUser = '/users/current',
	UpdateCurrentUser = '/users/current',
	GetUserByID = '/users/users/{mail}',
	GetCurrentUserContact = '/users/contacts/get/{filter}',
	UpdateCurrentUserTour = 'users/current/tour/done/true',
	checkUser = '/users/users/check/{mail}',
	UpdateCurrentUserCol = 'users/current/todos/board/col',
	GetAllUsersJobList = 'users/fivedays/jobList',

	// Register
	RegisterAdmin = '/register',
	RegisterUser = '/register/user',

	// Settings

	GetOrganization = '/settings/organizations',
	GetUsersForOrganizations = '/settings/users',
	UpdateUser = '/settings/users',
	RevokeUser = '/settings/users/{userToRevokeId}',
	UpdateCompany = '/settings/company',
	UpdateCompanyUserAllowOpt = '/settings/usersAllow/options/set',
	DeleteCompanyLogo = '/settings/company/logo',
	DownloadCompanyLogo = '/settings/company/download/logo',
	getAllRequestForACompany = '/settings/company/request/new/users',
	AcceptOrRevoqueARequest = '/settings/company/request/new/users/accept',

	// SettingsFivedays

	GetAllOrganization = '/fivedays/organizations',
	deleteOranization = '/fivedays/{companyId}',
	UpdateDomainsOrganization = '/fivedays/organizations/domain',
	RestoreOrderTodos = 'fivedays/order/todos',

	// Invite

	RegisterSendInvitation = '/invites/register',
	SettingsSendInvitation = '/invites/settings',
	CompleteInvitation = 'invites/completeInvite',
	GetInvitationData = 'invites/invitationData/{invitationId}',
	ResendInvitation = 'invites/resendInvitation',
	GetInvitationLink = 'invites/invitation/link/{userId}',

	// ShareMeeting

	ShareMeeting = '/meetings/{eventId}/{mail}/share',
	GetShareDetail = '/sharing/{shareId}',
	SendTokenRequest = '/sharing/{shareId}/{mail}',
	CheckSecurityCode = '/sharing/{shareId}/check/{code}',
	RequestNewToken = '/sharing/newToken/{mail}/{shareId}',
	GetSharedMeeting = '/sharing/share/get/{shareId}/meeting',
	GetSelectMeetingTodosForShared = 'sharing/todos/selectMeeting/get/{iCalUId}',
	GetSelectMeetingNotesForShared = '/sharing/notes/all/selectMeeting/get/{iCalUId}/{userId}',
	GetSelectMeetingAgendaForShared = '/sharing/agenda/items/all/selectMeeting/get/{iCalUId}',
	GetSelectMeetingFilesShared = '/sharing//files/get/file/all/selectMeeting/one/{iCalUId}/{userId}',
	DownloadSelectMeetingFilesShare = '/sharing/file/get/selectMeeting/one/{iCalUId}/file/download/{fileId}',
	GetTodosForNotesShare = '/sharing/notes/selectMeeting/get/note/todos/all/shared/one/all/{notesId}',
	ShareMeetingPostTodo = '/sharing/postTodo/{shareId}',
	ShareMeetingUpdateTodo = '/sharing/todo/update/{todoId}',
	ShareMeetingDeleteTodo = '/sharing/todo/delete/one/{todoId}',
	ShareMeetingPostAgenda = '/sharing/post/one/agendaItem',
	ShareMeetingPatchAgenda = '/sharing/update/agenda/one/item',
	ShareMeetingDeleteAgenda = '/sharing/agenda/delete/{itemId}',
	ShareMeetingPostNote = '/sharing/post/one/note/meeting/{shareId}',
	ShareMeetingUpdateNote = '/sharing/update/note/meeting/one/item/{notesId}',
	ShareMeetingDeleteNote = '/sharing/note/meeting/delete/{notesId}',
	ShareMeetingPostFiles = '/sharing/post/one/file/meeting/get/{iCalUId}',
	ShareMeetingDeleteFiles = '/sharing/files/delete/{iCalUId}/{fileId}/{userId}',
	ShareMeetingUpdateFiles = '/sharing/files/update/{iCalUId}/{fileId}/{userId}',
	ShareMeetingCheckUsersFD = '/sharing/users/selectMeeting/user/get/check/all/fivedays/users/boolean/avatar/{mail}',
	getMeetingCollaborators = '/sharing/users/selectMeeting/user/get/sharing/all/fivedays/meetings/mail/collaborators/shared/{iCalUId}',
	deleteMeetingCollaborators = '/sharing/meeting/shared/collaborator/delete/{iCalUId}/{mail}',
	GetSharedMeetingPrev = '/sharing/share/get/{shareId}/meeting/{token}/prev',
	GetSharedMeetingNext = '/sharing/share/get/{shareId}/meeting/{token}/next',
	PostPeopleSharePublic = '/sharing/people/share/post/{notesId}',
	DeletePeopleSharePublic = '/sharing/people/share/delete/{notesId}/{mail}',
	GetPeopleSharePublic = '/sharing/people/share/get/{notesId}',
	PostPeopleShareFileShare = '/sharing/files/people/share/post/file/{fileId}',
	DeletePeopleShareFileShare = '/sharing/files/people/share/delete/file/{fileId}/{mail}',
	GetPeopleShareFileShare = '/sharing/files/people/share/get/file/{fileId}',

	// Notes

	GetOneNote = '/notes/{notesId}',
	GetAllNote = '/notes',
	PostNotes = '/notes',
	DeleteNote = '/notes/{notesId}',
	UpdateNote = '/notes/update/{notesId}',
	AddTagsToNote = '/notes/tags',
	DeleteTagsFromNote = '/notes/{notesId}/{tagsId}',
	getNotesForASelectedMeeting = '/notes/selectMeeting/{iCalUId}',

	PostPeopleShare = '/notes/people/share/post/{notesId}',
	DeletePeopleShare = '/notes/people/share/delete/{notesId}/{mail}',
	GetPeopleShare = '/notes/people/share/get/{notesId}',

	// Todos

	PostTodo = '/todos',
	GetCurrentUserTodos = '/todos',
	GetCurrentUserTodosAssignee = '/todos/current/assignee',
	GetSelectMeetingTodos = 'todos/{eventId}',
	GetNoteTodos = 'todos/note/todo/{notesId}',
	UpdateTodo = '/todos/update/{todoId}',
	AddTagsForTodo = '/todos/tags',
	DeleteTagsFromTodo = '/todos/{todoId}/{tagsId}',
	DeleteTodo = '/todos/{todoId}',
	CompleteTodo = '/todos/update/complete/{todoId}',
	getOneTodo = '/todos/get/one/todo/update/{todoId}',

	// AGENDA
	getAllAgenda = '/agenda',
	PostItemAgenda = '/agenda',
	GetAgendaForMeeting = '/agenda/{iCalUId}',
	DeleteAgendaItem = '/agenda/{itemId}',
	UpdateItemAgenda = '/agenda/update',

	// SERIES

	GetAllMeetingsForASerie = '/series/{iCalUId}',
	PostMeetingSerie = '/series/',

	// NOTIFICATIONS

	sendNotification = '/notifications/create',
	updateNewNotifications = '/notifications/updateNew',
	updateSeenNotifications = '/notifications/seen/{notificationId}',
	getCurrentUserNotifications = '/notifications/currentUser/get',
	hideNotifications = '/notifications/hide/{notificationId}',

	// COMMENTS

	getCommentToNote = '/comments/{notesId}',
	getCommentToNoteForMeeting = '/comments/meeting',
	postCommentToNote = '/comments/',
	deleteCommentFromANote = '/comments/delete/{commentId}',
	updateCommentFromANote = '/comments/update/{commentId}',

	// PROJECT

	GetUserProjects = '/project/',
	PostProjects = '/project/',
	UpdateProjects = '/project/',
	DeleteProject = '/project/{projectId}',
	GetOneProject = '/project/one/{projectId}',

	AddTagsToProject = '/project/tags',
	DeleteTagsFromProject = '/project/{projectId}/{tagsId}',

	PostPeopleShareProject = '/project/people/share/post/{projectId}',
	DeletePeopleShareProject = '/project/people/share/delete/{projectId}/{mail}',
	GetPeopleShareProject = '/project/people/share/get/{projectId}',

	GetNotesProject = '/project/notes/{projectId}',
	GetTodosProject = '/project/todos/{projectId}',
	GetMeetingsProject = '/project/meetings/{projectId}',
	GetNotesCommentsProject = '/project/comments/{projectId}',
	PostMeetingsProject = '/project/meeting/{projectId}',

	PostNotificationProject = '/project/notification',
	SeeNotificationProject = '/project/notification/seen',
	GetNotificationsProject = '/project/notification/{projectId}',

	AddProjectFile = '/project/{projectId}/files',
	DeleteProjectFile = '/project/{projectId}/files/{fileId}',
	UpdateProjectFile = '/project/{projectId}/files/{fileId}',
	GetProjectFile = '/project/{projectId}/files/get/by/project',
	DownloadProjectFile = '/project/{projectId}/files/download/{fileId}',

	PostPeopleShareFile = '/project/people/share/post/file/{fileId}',
	DeletePeopleShareFile = '/project/people/share/delete/file/{fileId}/{mail}',
	GetPeopleShareFile = '/project/people/share/get/file/{fileId}',

	// COLUMN CUSTOM

	GetUserCustomColumn = '/column/user/{userId}',
	GetProjectCustomColumn = '/column/project/{projectId}',
	createCustomColumn = '/column/',
	updateCustomColumn = '/column/',
	deleteCustomColumn = '/column/{columnId}',

	// BILLING

	GetBillingInfo = '/billing/',

	// PREREGISTER

	checkExistingCompany = '/preregister/{domain}',
	subscribeForUpdateWithoutMicrosoft = '/preregister/subscribe/{mail}',
	checkForRequestStatus = '/preregister/check/request/{mail}',
	checkForUserExist = '/preregister/check/user/register/{mail}',
	sendRequest = '/preregister/request/',
	checkForUserInviteData = '/preregister/check/invitationData/{invitationId}',

	// BOT

	CreateMeetingBotData = '/recall/',
	GetMeetingBotData = '/recall/{iCalUId}',
	DeleteMeetingBotData = '/recall/{iCalUId}',
	GetMeetingBotDataTranscript = '/recall/transcript/{botId}',
	RunBotAnalyze = '/recall/bot/analyze/{botId}',
	GenerateSummary = '/recall/mistral/summary',
	GenerateTodos = '/recall/mistral/generate/todos/{botId}',
}

export class UrlParamBuilder {
	private params: any = {};

	public addEventId(id: string): UrlParamBuilder {
		this.params.eventId = id;
		return this;
	}

	public addEventiCalUId(id: string): UrlParamBuilder {
		this.params.iCalUId = id;
		return this;
	}

	public addUserId(id: string | undefined): UrlParamBuilder {
		this.params.userId = id;
		return this;
	}

	public addUserMail(mail: string | undefined): UrlParamBuilder {
		this.params.mail = mail;
		return this;
	}

	public addFileId(id: string | undefined): UrlParamBuilder {
		this.params.fileId = id;
		return this;
	}

	public adduserToRevokeId(id: string | undefined): UrlParamBuilder {
		this.params.userToRevokeId = id;
		return this;
	}

	public addInvitationId(id: string | undefined): UrlParamBuilder {
		this.params.invitationId = id;
		return this;
	}

	public addNotesId(id: string | undefined): UrlParamBuilder {
		this.params.notesId = id;
		return this;
	}

	public addShareId(id: string | undefined): UrlParamBuilder {
		this.params.shareId = id;
		return this;
	}

	public addCode(code: string | undefined): UrlParamBuilder {
		this.params.code = code;
		return this;
	}

	public addToken(token: string | undefined): UrlParamBuilder {
		this.params.token = token;
		return this;
	}

	public addFilter(filter: string | undefined): UrlParamBuilder {
		this.params.filter = filter;
		return this;
	}

	public addtagsId(tagsId: string | undefined): UrlParamBuilder {
		this.params.tagsId = tagsId;
		return this;
	}

	public addtodoId(todoId: string | undefined): UrlParamBuilder {
		this.params.todoId = todoId;
		return this;
	}

	public addItemId(itemId: string | undefined): UrlParamBuilder {
		this.params.itemId = itemId;
		return this;
	}

	public addSeriesId(seriesId: string | undefined): UrlParamBuilder {
		this.params.seriesId = seriesId;
		return this;
	}

	public addStartDate(startDate: string | undefined): UrlParamBuilder {
		this.params.startDate = startDate;
		return this;
	}

	public addendDate(endDate: string | undefined): UrlParamBuilder {
		this.params.endDate = endDate;
		return this;
	}

	public addNotificationId(notificationId: string | undefined): UrlParamBuilder {
		this.params.notificationId = notificationId;
		return this;
	}

	public addCommentId(commentId: string | undefined): UrlParamBuilder {
		this.params.commentId = commentId;
		return this;
	}

	public addProjectId(projectId: string | undefined): UrlParamBuilder {
		this.params.projectId = projectId;
		return this;
	}

	public addColmunId(columnId: string | undefined): UrlParamBuilder {
		this.params.columnId = columnId;
		return this;
	}

	public addCompanyId(companyId: string | undefined): UrlParamBuilder {
		this.params.companyId = companyId;
		return this;
	}

	public addDomain(domain: string | undefined): UrlParamBuilder {
		this.params.domain = domain;
		return this;
	}

	public addBotId(botId: string | undefined): UrlParamBuilder {
		this.params.botId = botId;
		return this;
	}

	public build(): any {
		return this.params;
	}
}
