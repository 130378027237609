/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './meetings-details-description.module.scss';
import 'react-quill/dist/quill.snow.css';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import {
	getDateFormat,
	getDateFormatL,
	getDayPastOneDay,
	getStartOfDay,
	getStartOfDayDate,
	getTimeFormat,
} from '../../../../../../shared/utils/date.util';
import MeetingDetailsTags from './meeting-details-header-tags';
import { TagContract } from '../../../../../../store/meetings/types';
import {
	IconClock,
	IconEdit,
	IconGroupS,
	IconLocalisation,
	IconMeetings,
	IconPrev,
	IconProject,
	IconTag,
	IconText,
} from '../../../../../../shared/utils/icon';
import { getMeetings, setSelectedMeeting } from '../../../../../../store/meetings/slice';
import { RouteSearchParams, RouteUrls } from '../../../../../../routes/routes-config';
import { useNavigate } from 'react-router-dom';
import MeetingDetailsProjectConnect from './modal/meeting-details-project-connect';
import googleImg from '../../../../../../../images/icones/google-meets.svg';

export interface MeetingDetailsHeaderProps {
	handleTagsChanged: (tags: TagContract[]) => void;
}

const MeetingDetailsDescription = ({ handleTagsChanged }: MeetingDetailsHeaderProps) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { t } = useTranslation();
	const { selectedMeeting, selectedMeetingInformation, prevMeeting } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);

	const IconTeams = 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';

	let text = '';

	if (selectedMeetingInformation) {
		const data =
			selectedMeetingInformation.textMeeting !== ''
				? JSON.parse(selectedMeetingInformation.textMeeting)
				: undefined;

		if (data && data.content && Array.isArray(data.content)) {
			for (const content of data.content) {
				if (content && content.content && Array.isArray(content.content) && content.content.length > 0) {
					text += content.content[0].text || '';
				}
			}
		}
	}

	const [projectConnect, setHantleProjectConnect] = React.useState(false);

	const handleProjectConnect = () => {
		setHantleProjectConnect(!projectConnect);
	};

	const insertHtml = (body: string) => {
		const removeUnderscore = body?.replace(/<[^>]*>[^<]*_{5,}[^<]*<\/[^>]*>/g, '');
		return removeUnderscore;
	};

	return (
		<div className={styles.bodyMeeting}>
			<div className={styles.info}>
				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconPrev />
						</div>
						{t('Previous meeting')}
					</div>
					<div className={styles.des}>
						{prevMeeting && prevMeeting.seriesMasterId === selectedMeeting?.seriesMasterId && (
							<div
								onClick={() => {
									if (user) {
										const startDate = getStartOfDayDate(new Date(prevMeeting.start));
										const endDate = getDayPastOneDay(new Date(prevMeeting.start));
										const dateRange = { startDate, endDate };
										dispatch(getMeetings(dateRange)).then(() => {
											const params: any = {};
											params[RouteSearchParams.Date] = getStartOfDay(prevMeeting.start);
											params[RouteSearchParams.MeetingId] = prevMeeting.iCalUId;
											navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
										});
									} else {
										dispatch(setSelectedMeeting(prevMeeting));
									}
								}}
								className={styles.slot}>
								{prevMeeting.subject} ({getDateFormatL(prevMeeting.start)})
							</div>
						)}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconProject height='20' width='20' />
						</div>
						{t('Connected project')}
					</div>
					<div className={styles.des}>
						{selectedMeeting && projects?.find((project) => project.id === selectedMeeting.projectId) && (
							<div
								onClick={() => {
									const params: any = {};
									params[RouteSearchParams.ProjectId] = selectedMeeting.projectId;
									navigate(`${RouteUrls.Project}?${new URLSearchParams(params).toString()}`);
								}}
								className={styles.slot}>
								{projects?.find((project) => project.id === selectedMeeting.projectId)?.projectName}
							</div>
						)}
						{user && (
							<div onClick={() => handleProjectConnect()} className={styles.icon}>
								<IconEdit />
							</div>
						)}

						{projectConnect && <MeetingDetailsProjectConnect handleClose={handleProjectConnect} />}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconText />
						</div>
						{t('Description')}
					</div>
					<div className={styles.descriptionText}>{selectedMeetingInformation && text}</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconMeetings />
						</div>
						{t('Date')}
					</div>
					<div className={styles.des}>
						{selectedMeeting && getDateFormat(new Date(selectedMeeting.start))}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconClock />
						</div>

						{t('Hour')}
					</div>
					<div className={styles.des}>
						{selectedMeeting && selectedMeeting.isAllDay === false ? (
							<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
						) : (
							<span>{t('All day')}</span>
						)}
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconLocalisation />
						</div>

						{t('Localisation')}
					</div>
					<div className={styles.des}>{selectedMeeting && selectedMeeting.location}</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconGroupS width='20' height='20' />
						</div>

						{t('Online meeting')}
					</div>
					<div className={styles.des}>
						<div className={styles.link}>
							{selectedMeeting?.onlineMeeting ? (
								<>
									<img src={IconTeams} alt='teams icon' />
									<a href={selectedMeeting?.onlineMeeting} target='_blank' rel='noreferrer'>
										{t('Join')}
									</a>
								</>
							) : (
								<>
									{selectedMeeting?.googleMeetUrl ? (
										<>
											<img src={googleImg} alt='teams icon' />
											<a href={selectedMeeting?.googleMeetUrl} target='_blank' rel='noreferrer'>
												{t('Join')}
											</a>
										</>
									) : (
										<>
											{selectedMeeting?.zoomUrl && (
												<>
													<a href={selectedMeeting?.zoomUrl} target='_blank' rel='noreferrer'>
														{t('Join')}
													</a>
												</>
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.title}>
						<div className={styles.icon}>
							<IconTag />
						</div>
						{t('Tags')}
					</div>
					<div className={styles.des}>
						{selectedMeeting && (
							<MeetingDetailsTags meeting={selectedMeeting} handleTagsChanged={handleTagsChanged} />
						)}
					</div>
				</div>
			</div>
			{selectedMeeting ? (
				<div
					className={styles.descriptionGraph}
					dangerouslySetInnerHTML={{ __html: insertHtml(selectedMeeting.bodyContent) }}></div>
			) : (
				<></>
			)}
		</div>
	);
};

export default MeetingDetailsDescription;
