import React, { useEffect, useState } from 'react';
import styles from './project-todos.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { IconMeetings, IconSimplePlus, IconStatus, IconTag } from '../../../../../shared/utils/icon';
import DetailsTodosFormComponents from './project-todos-form';
import TodosViewSimple from '../../../../todos/components/todos-view-simple';
import {
	deleteTodoProject,
	getTodosProject,
	sortTodosProject,
	updateTodoProject,
} from '../../../../../store/project/slice';
import { TodoAttendee, TodosContract } from '../../../../../store/todos/type';
import OutlinedButton from '../../../../../shared/components/buttons/outlinedBtn/outlined-btn.component';
import { getCustomColumnForAProject } from '../../../../../store/column/slice';
import { TodosColumns } from '../../../../../shared/hooks/use-todos-colums.hook';
import AttendeeCell from '../../../../todos/components/attendee-cell.component';
import DateCell from '../../../../todos/components/date-cell.component';
import StateCell from '../../../../todos/components/state-cell.component';
import TagsCell from '../../../../todos/components/tags-cell.component';
import { SectionType } from '../../../../../shared/hooks/use-todos-mutations.hook';
import TodoFilters, { filterConfigurations } from '../../../../todos/components/todo-filters.component';
import TodosViewBoard from './todo-view-board';

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

const ProjectTodos = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { projectsTodos, selectProject, projectsSharePeople } = useAppSelector((state) => state.project);
	const [filteredTodosList, setFilteredTodosList] = useState<TodosContract[]>(projectsTodos ?? []);

	useEffect(() => {
		const fetchData = async () => {
			if (selectProject) {
				await dispatch(getTodosProject(selectProject.id));
				await dispatch(getCustomColumnForAProject(selectProject.id));
			}
		};
		fetchData();
	}, [selectProject]);

	const handleFilteredTodo = (todos: TodosContract[]) => {
		setFilteredTodosList(todos);
	};

	const storedView = localStorage.getItem('viewTodo');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');
	const [meetings, setMeetings] = React.useState<Meeting[]>([]);
	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodo', val);
		setView(val);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [todosColumns] = useState<TodosColumns[]>([
		{
			icon: IconMeetings,
			title: t('Due date'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<DateCell updateFn={updateTodoProject} todo={todo} sectionType={SectionType.project} />
			),
		},
		{
			icon: IconMeetings,
			title: t('Assignee'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<AttendeeCell
					updateFn={updateTodoProject}
					todo={todo}
					attendees={projectsSharePeople?.map(
						(attendee) => new TodoAttendee(attendee.mail, attendee.username),
					)}
				/>
			),
		},
		{
			icon: IconStatus,
			title: t('State'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<StateCell updateFn={updateTodoProject} todo={todo} sectionType={SectionType.project} />
			),
		},
		{
			icon: IconTag,
			title: t('Tags'),
			width: 200,
			isActive: true,
			component: (todo) => (
				<TagsCell sectionType={SectionType.project} updateFn={updateTodoProject} todo={todo} />
			),
		},
	]);

	React.useEffect(() => {
		if (filterConfigurations.every((filter) => !filter.isActive)) {
			setFilteredTodosList(projectsTodos ?? []);
		}
	}, [projectsTodos, filterConfigurations]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	React.useEffect(() => {
		if (projectsTodos) {
			const uniqueMeetings = new Map<string, Meeting>();
			dispatch(sortTodosProject());
			projectsTodos.forEach((todo) => {
				const meetingKey = `${todo.meetingName}-${todo.meetingStartDate}`;
				uniqueMeetings.set(meetingKey, {
					meetingName: todo.meetingName,
					meetingStartDate: todo.meetingStartDate,
					meetingGraphId: todo.meetingGraphId,
					graphiCalUId: todo.graphiCalUId,
				});
			});
			const meetingsArray = [...uniqueMeetings.values()];
			setMeetings(meetingsArray);
		}
	}, [projectsTodos]);

	// ----

	const [showTodo, setShowTodo] = React.useState<boolean>(true);
	const [showDone, setShowDone] = React.useState<boolean>(view === 'Board' ? true : false);
	const [showArchived, setArchived] = React.useState<boolean>(false);
	const [showDoing, setShowDoing] = React.useState<boolean>(true);
	const [showBlock, setShowBlock] = React.useState<boolean>(true);

	const handleShowTodo = (show: boolean) => {
		setShowTodo(show);
	};

	const handleShowDone = (show: boolean) => {
		setShowDone(show);
	};

	const handleShowArchived = (show: boolean) => {
		setArchived(show);
	};

	const handleShowDoing = (show: boolean) => {
		setShowDoing(show);
	};

	const handleShowBlock = (show: boolean) => {
		setShowBlock(show);
	};

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	const trueCount = [showTodo, showDone, showArchived, showDoing, showBlock].filter(Boolean).length;

	return (
		<>
			<div className={styles.over}>
				<div className={styles.top}>
					<div className={styles.flexButton}>
						<OutlinedButton onClick={() => handleMenu()}>
							<IconSimplePlus /> {t('Add new todo')}
						</OutlinedButton>
						{menu && (
							<div ref={modalRef}>
								<DetailsTodosFormComponents close={handleMenu} />
							</div>
						)}
					</div>
				</div>
				<TodoFilters
					todos={projectsTodos ?? []}
					handleGroupChange={handleGroupChange}
					view={view}
					handleFilteredTodos={handleFilteredTodo}
					sectionType={SectionType.project}
				/>
				<div className={styles.main}>
					<div className={styles.overview}>
						{view === 'Simple' && projectsTodos && (
							<div className={styles.projectRowList}>
								<TodosViewSimple
									deleteTodoFn={deleteTodoProject}
									updateTitleFn={updateTodoProject}
									updateTodoFn={updateTodoProject}
									sectionType={SectionType.project}
									todosColumns={todosColumns}
									height='calc(100vh - 430px)'
									attendees={projectsSharePeople?.map((attendee) => ({
										name: attendee.username,
										email: attendee.mail,
									}))}
									todosAssignee={[...filteredTodosList].sort(
										(a: TodosContract, b: TodosContract) =>
											Date.parse(b.createdOn.toString()) - Date.parse(a.createdOn.toString()),
									)}
								/>
							</div>
						)}

						{view === 'Board' && projectsTodos && (
							<>
								<div className={styles.mainTodoBoardPage}>
									<TodosViewBoard heightView='calc(100vh - 409px)' todosAssignee={projectsTodos} />
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default ProjectTodos;
