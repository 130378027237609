/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from 'react';
import { TableExtension } from '@remirror/extension-react-tables';
import MentionSuggestor from './mention-suggestor.component';
import {
	BoldExtension,
	ItalicExtension,
	HeadingExtension,
	DropCursorExtension,
	ImageExtension,
	LinkExtension,
	OrderedListExtension,
	BulletListExtension,
	UnderlineExtension,
	FontSizeExtension,
	StrikeExtension,
	TextColorExtension,
	ColumnsExtension,
	BlockquoteExtension,
	HorizontalRuleExtension,
	NodeFormattingExtension,
	CodeBlockExtension,
	CodeExtension,
	TextHighlightExtension,
	HistoryExtension,
	AnnotationExtension,
	YjsExtension,
	MentionAtomExtension,
} from 'remirror/extensions';
import { useRemirror, Remirror, EditorComponent, OnChangeJSON } from '@remirror/react';
import { RemirrorJSON, SuggestExtension, htmlToProsemirrorNode } from 'remirror';
import css from 'refractor/lang/css.js';
import javascript from 'refractor/lang/javascript.js';
import json from 'refractor/lang/json.js';
import markdown from 'refractor/lang/markdown.js';
import typescript from 'refractor/lang/typescript.js';

import { languages } from '@codemirror/language-data';
import { oneDark } from '@codemirror/theme-one-dark';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';

import * as Y from 'yjs';

import 'remirror/styles/all.css';
import { TodosNodeExtension } from './editor menu/todos-extension.component';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { NotesContract } from '../../../store/notes/type';
import { MenuFixed } from './editor menu/menu-fix';
import { AccessRightType, MeetingContract } from '../../../store/meetings/types';
import { useTranslation } from 'react-i18next';

import styles from './note-editor.module.scss';
import { YjsSyncExtension } from './editor menu/yjs-sync-extension';
import { useEditor } from '../../../shared/contexts/editor-context';

export const usercolors = ['#30bced', '#6eeb83', '#ffbc42', '#ecd444', '#ee6352', '#9ac2c9', '#8acb88', '#1be7ff'];

interface Props {
	content: string;
	onChangeHandlerText: (content: string) => void;
	handleEditingChange: () => void;
	provider: any;
	doc: any;
	inviteUser: string;
	noteId: string;
	note: NotesContract;
	userName: string;
	meetingPage?: boolean;
	onChangeHandlerTitle?: (title: string) => void;
	meeting?: MeetingContract;
	title: string;
}

function parseText(text: string) {
	const paragraphs = text.split(/\n\s*\n/);

	return paragraphs.map((paragraph) => {
		// Replace bold text with appropriate JSON format
		const content = [];
		let lastIndex = 0;
		const boldPattern = /\*\*(.*?)\*\*/g;
		let match;

		while ((match = boldPattern.exec(paragraph)) !== null) {
			if (match.index > lastIndex) {
				content.push({ type: 'text', text: paragraph.substring(lastIndex, match.index) });
			}
			content.push({ type: 'text', text: match[1], marks: [{ type: 'bold' }] });
			lastIndex = boldPattern.lastIndex;
		}

		if (lastIndex < paragraph.length) {
			content.push({ type: 'text', text: paragraph.substring(lastIndex) });
		}

		return { type: 'paragraph', content };
	});
}

function buildRemirrorDoc(parsedContent: any) {
	return {
		type: 'doc',
		content: parsedContent,
	};
}

function convertToRemirrorContent(text: string) {
	// Parse the text
	const parsedContent = parseText(text);

	// Build the Remirror document
	return buildRemirrorDoc(parsedContent);
}

export function NotesEditorComponent({
	content,
	onChangeHandlerText,
	handleEditingChange,
	provider,
	doc,
	inviteUser,
	noteId,
	note,
	userName,
	meetingPage,
	onChangeHandlerTitle,
	meeting,
	title,
}: Props) {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const { isModalOpen } = useEditor();

	const [myColor] = useState(() => usercolors[Math.floor(Math.random() * usercolors.length)]);

	const awareness = provider?.awareness;

	if (awareness) {
		awareness.setLocalStateField('user', {
			name: user ? user?.displayName : inviteUser,
			color: myColor,
		});
	}

	// useEffect(() => {
	// 	if (user) {
	// 		dispatch(getNotesTodos(noteId));
	// 	} else {
	// 		dispatch(getNotesTodosShared(noteId));
	// 	}
	// }, [content]);

	useEffect(() => {
		const handleUpdate = (update: Uint8Array) => {
			Y.applyUpdate(doc, update);
		};

		doc?.on('update', handleUpdate);

		return () => {
			doc?.off('update', handleUpdate);
		};
	}, [doc]);

	const linkExtension = new LinkExtension({ autoLink: true, selectTextOnClick: true });

	linkExtension.addHandler('onClick', (_, data) => {
		const newWindow = window.open(data.href, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = undefined;
		return true;
	});

	const typing = convertToRemirrorContent(note.text);

	const { manager, state } = useRemirror({
		extensions: () => [
			linkExtension,
			new BoldExtension({}),
			new ItalicExtension(),
			new HeadingExtension({}),
			new ImageExtension({ enableResizing: true }),
			new DropCursorExtension({}),
			new BulletListExtension({ enableSpine: true }),
			new OrderedListExtension({}),
			new HeadingExtension({}),
			new SuggestExtension(),
			new MentionAtomExtension({
				matchers: [{ name: 'at', char: '@' }],
			}),
			new HeadingExtension({}),
			new UnderlineExtension({}),
			new FontSizeExtension({ defaultSize: '16px' }),
			new StrikeExtension({}),
			new TextColorExtension({}),
			new ColumnsExtension({}),
			new BlockquoteExtension(),
			new TableExtension({ resizable: true }),
			new HorizontalRuleExtension({}),
			new NodeFormattingExtension({}),
			new CodeBlockExtension({
				supportedLanguages: [css, javascript, json, markdown, typescript],
			}),
			new CodeExtension(),
			new TextHighlightExtension({}),
			new HistoryExtension({}),
			new CodeMirrorExtension({ languages, extensions: [oneDark] }),
			new AnnotationExtension({}),
			new YjsExtension({ getProvider: () => provider }),
			new TodosNodeExtension({ disableExtraAttributes: true }),
			new YjsSyncExtension({ doc }), // Pass the Yjs document to the extension
		],
		content: note.text.startsWith('{')
			? JSON.parse(note.text)
			: note.text.startsWith('<')
			? note.text
			: note.text === ''
			? undefined
			: typing,
		selection: 'start',
		stringHandler: htmlToProsemirrorNode,
	});

	const isEmptyJSON = useCallback((Json: RemirrorJSON) => {
		return (
			!Json ||
			!Json.content ||
			Json.content.every((node) => node.type === 'paragraph' && (!node.content || node.content.length === 0))
		);
	}, []);

	const handleEditorChange = useCallback(
		(Json: RemirrorJSON) => {
			if (content !== JSON.stringify(Json)) {
				handleEditingChange();
				onChangeHandlerText(isEmptyJSON(Json) ? '' : JSON.stringify(Json));
			}
		},
		[content, handleEditingChange, isEmptyJSON, onChangeHandlerText],
	);

	return (
		<div className={styles.editor} id='editorNote'>
			<div className='remirror-theme'>
				<Remirror
					manager={manager}
					editable={!isModalOpen}
					initialContent={state}
					placeholder={t('Write something here') as string}>
					<MenuFixed
						userId={user ? user.graphUserId : inviteUser}
						noteId={noteId}
						manager={manager}
						userName={user ? user.displayName : inviteUser}
						userMail={user ? user.email : inviteUser}
						note={note}
						meeting={meeting}
					/>

					{meetingPage && onChangeHandlerTitle && (
						<input
							className={styles.meetingTitleInput}
							defaultValue={title}
							onChange={(e) => onChangeHandlerTitle(e.target.value)}
						/>
					)}

					<EditorComponent />

					{note.accessRightType !== AccessRightType.ONLYME && (
						<MentionSuggestor noteId={note.id} noteTitle={note.title} note={note} />
					)}

					{/* <FloatingLinkToolbar
						userId={user ? user.graphUserId : inviteUser}
						noteId={noteId}
						userName={user ? user.displayName : inviteUser}
						userMail={user ? user.email : inviteUser}
						note={note}
						meeting={meeting}
					/> */}

					<OnChangeJSON onChange={handleEditorChange} />
				</Remirror>
			</div>
		</div>
	);
}

export default NotesEditorComponent;
