/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './meeting-collaboration.module.scss';
import { IconCheck, IconClose, IconLinkEx, IconShare } from '../../../../../../shared/utils/icon';
import MeetingCollaborationSharingModal from './meeting-collaboration-sharing.modal';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
	deleteMeetingCollaborator,
	getMeetingsCollaborators,
	shareMeeting,
} from '../../../../../../store/sharing/slice';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { checkUserFromFivedays } from '../../../../../../store/user/slice';
import { PersonaSize } from '@fluentui/react';

const MeetingCollaborationComponent = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [toggleSharingOption, setToggleSharingOption] = React.useState(false);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { shareCollaborators } = useAppSelector((state) => state.sharing);
	const [link, setLink] = React.useState('');

	const handleToggleSharingOption = () => {
		setToggleSharingOption(!toggleSharingOption);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleToggleSharingOption();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleToggleSharingOption]);

	const [linkCopied, setLinkCopied] = React.useState(false);

	let timeoutId: any;

	const handleLinkCopied = () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setLinkCopied(false);
		}, 2000);
	};

	const shareLink = () => {
		selectedMeeting &&
			dispatch(shareMeeting({ eventId: selectedMeeting.graphEventId, mail: 'none' })).then((res) => {
				setLink(res.payload as string);
				navigator.clipboard.writeText(res.payload as string);
				setLinkCopied(true);
				handleLinkCopied();
			});
	};

	const handleDeleteCollab = (mail: string) => {
		selectedMeeting &&
			dispatch(deleteMeetingCollaborator({ iCalUId: selectedMeeting.iCalUId, mail })).then(() => {
				dispatch(getMeetingsCollaborators(selectedMeeting.iCalUId));
			});
	};

	const [InviteSend, setInviteSend] = React.useState(false);

	const handleInviteSend = () => {
		setInviteSend(!InviteSend);
	};

	const [userAttendees, setUserAttendees] = React.useState<
		{ type: string; emailAddress: { name: string; address: string } }[]
	>([]);

	React.useEffect(() => {
		if (selectedMeeting) {
			const fetchFilteredAttendees = async () => {
				const filteredAttendees = [];

				for (const attendee of selectedMeeting.attendees) {
					const address = attendee.emailAddress.address;
					const res = await dispatch(checkUserFromFivedays(address));

					if (res.payload === true) {
						filteredAttendees.push(attendee);
					}
				}

				setUserAttendees(filteredAttendees);
			};

			fetchFilteredAttendees();
		}
	}, [selectedMeeting, dispatch]);

	return (
		<>
			<div className={styles.main}>
				{user && (
					<div>
						{!InviteSend ? (
							<button className={styles.unshare} onClick={() => handleToggleSharingOption()}>
								<IconShare />
								{t('Share this meeting')}
							</button>
						) : (
							<button className={styles.shareButton}>
								<span>
									<IconCheck />
								</span>
								{t('Thanks, all invitations have been sent')}
							</button>
						)}

						{selectedMeeting && toggleSharingOption && (
							<div ref={modalRef}>
								<MeetingCollaborationSharingModal
									onClose={handleToggleSharingOption}
									meeting={selectedMeeting}
									inviteSend={handleInviteSend}
									users={userAttendees}
								/>
							</div>
						)}
						<div className={styles.shareDiv}>
							<p className={styles.share}>
								<IconLinkEx />
								<span>
									{t('Or share this meeting via a ')}{' '}
									<span onClick={() => shareLink()} className={styles.link}>
										{t('share link')}
									</span>
								</span>
							</p>
							{linkCopied && (
								<div className={styles.buttonLinkCopied}>
									<span>
										<IconCheck />
									</span>
									{t('Link copied')}
								</div>
							)}
						</div>
					</div>
				)}
				<div>
					<p className={styles.subTitle}>{t('Collaborators')}</p>
					<div className={styles.collaborators}>
						{selectedMeeting && (
							<div className={styles.row}>
								<div className={styles.avatar}>
									<Avatar
										size={PersonaSize.size32}
										name={selectedMeeting.organizer.name}
										mail={selectedMeeting.organizer.address}
										index={1}
									/>
									<p>{selectedMeeting.organizer.name}</p>
								</div>
								<p className={styles.right}>{t('Creator')}</p>
							</div>
						)}

						{userAttendees
							.filter((attendee) => attendee.emailAddress.address !== selectedMeeting?.organizer.address)
							.map((attendee, index) => (
								<div className={styles.row} key={index}>
									<div className={styles.avatar}>
										<Avatar
											size={PersonaSize.size32}
											name={attendee.emailAddress.name}
											mail={attendee.emailAddress.address}
											index={1 + index}
										/>
										<p>{attendee.emailAddress.name}</p>
									</div>
								</div>
							))}

						{selectedMeeting?.attendees
							.filter(
								(attendee) =>
									attendee.emailAddress.address !== user?.email &&
									shareCollaborators?.includes(attendee.emailAddress.address),
							)
							.map((attendee, index) => (
								<div className={styles.row} key={index}>
									<div className={styles.avatar}>
										<Avatar
											size={PersonaSize.size32}
											name={attendee.emailAddress.name}
											mail={attendee.emailAddress.address}
											index={1 + index}
										/>
										<p>{attendee.emailAddress.name}</p>
									</div>
									{user && (
										<p
											onClick={() => handleDeleteCollab(attendee.emailAddress.address)}
											className={styles.right}>
											<IconClose />
										</p>
									)}
								</div>
							))}
					</div>
				</div>
			</div>
		</>
	);
};

export default MeetingCollaborationComponent;
