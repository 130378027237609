/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React, { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TodosContract, UpdateTodo } from '../../../store/todos/type';
import { deleteTodo, updateTodos } from '../../../store/todos/slice';

import { updateSelectedMeetingCountTodos } from '../../../store/meetings/slice';
import DeleteConfirmBox from '../../../shared/utils/delete-box';
import { updateProjectCountTodos } from '../../../store/project/slice';
import {
	deleteTodoShared,
	deleteTodosShare,
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
	updateTodosShared,
} from '../../../store/sharing/slice';
import { TodosColumns } from '../../../shared/hooks/use-todos-colums.hook';
import { SectionType } from '../../../shared/hooks/use-todos-mutations.hook';
import styles from '../todos.module.scss';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import { IconArchive, IconDelete, IconMore } from '../../../shared/utils/icon';
import TitleCell from './title-cell.component';
import { useTranslation } from 'react-i18next';
import { PayloadAction } from '@reduxjs/toolkit';

interface Props {
	singleTodo: TodosContract;
	handleUpdate: (NoteTD: TodosContract) => void;
	todosColumns: TodosColumns[];
	sectionType: SectionType;
	updateTitleFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>;
	deleteTodoFn: (value: string) => PayloadAction<string>;
	updateTodoFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>;
}

const TodosRowComponent: React.FC<Props> = ({
	singleTodo,
	handleUpdate,
	todosColumns,
	sectionType,
	updateTitleFn,
	deleteTodoFn,
	updateTodoFn,
}) => {
	const dispatch = useAppDispatch();

	const { user } = useAppSelector((state) => state.user);
	const { t } = useTranslation();

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [todoToUpdate, setTodoToUpdate] = React.useState<TodosContract | undefined>(undefined);
	const [openDotMenu, setOpenDotMenu] = useState<boolean>(false);
	const refDotMenu = useRef<HTMLDivElement | null>(null);
	useClickOutside(refDotMenu, () => setOpenDotMenu(false));

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleTodoClickOption = (NoteTD: TodosContract) => {
		setTodoToUpdate(NoteTD);
	};

	const handleDeleteTodo = () => {
		if (todoToUpdate) {
			if (todoToUpdate.graphiCalUId) {
				dispatch(updateSelectedMeetingCountTodos(todoToUpdate.graphiCalUId));
			}

			if (todoToUpdate.projectId) {
				dispatch(updateProjectCountTodos(todoToUpdate.projectId));
			}

			if (user) {
				if (user?.graphUserId === todoToUpdate.graphUserId) {
					dispatch(deleteTodo(todoToUpdate.id)).then(() => {
						dispatch(deleteTodoFn(todoToUpdate.id));
					});
				}
				setOpenConfirmBox(false);
			} else {
				dispatch(deleteTodoShared(todoToUpdate.id)).then(() => {
					dispatch(deleteTodosShare(todoToUpdate.id));
					setOpenConfirmBox(false);
				});
			}
		}
	};

	const handleArchivedTodo = (todo: TodosContract) => {
		if (todo) {
			const body = TodosContract.toArchived(todo);

			if (user) {
				dispatch(updateTodos({ body, todoId: todo.id })).then((res) => {
					if (res.meta.requestStatus === 'fulfilled') {
						const newTodo = res.payload as TodosContract;

						dispatch(updateTodoFn({ todoId: newTodo.id, newTodo }));
					}
				});
			} else {
				dispatch(updateTodosShared({ body, todoId: todo.id })).then((res) => {
					const newTodo = res.payload as TodosContract;
					dispatch(updateTodoMeetingShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoMeetingPrevShared({ todoId: newTodo.id, newTodo }));
				});
			}
		}
	};

	return (
		<>
			<div
				key={singleTodo.createdOn.toString()}
				className={styles.todoRow}
				style={{ gridTemplateColumns: `450px repeat(${todosColumns.length}, 1fr) 30px` }}>
				<TitleCell
					updateFn={updateTitleFn}
					sectionType={sectionType}
					todo={singleTodo}
					handleUpdate={handleUpdate}
				/>
				{todosColumns.map((column) => column.isActive && <>{column.component(singleTodo)}</>)}

				<div className={styles.more}>
					{user?.graphUserId === singleTodo.graphUserId && (
						<div className={styles.dotMenuContainer}>
							<div
								className={styles.icon}
								onClick={(e) => {
									e.stopPropagation();
									setOpenDotMenu(true);
								}}>
								<IconMore />
							</div>
						</div>
					)}
					{openDotMenu && (
						<ModalLayout ref={refDotMenu} right='10px' width='200px'>
							<div
								className={styles.dotMenuModalRow}
								onClick={(e) => {
									handleArchivedTodo(singleTodo);
									setOpenDotMenu(false);
									e.stopPropagation();
								}}>
								<IconArchive />
								<p>{t('Archived')}</p>
							</div>
							<div
								className={`${styles.dotMenuModalRow} ${styles.deleteMenuRow}`}
								onClick={(e) => {
									handleTodoClickOption(singleTodo);
									setOpenDotMenu(false);
									setOpenConfirmBox(!openConfirmBox);
									e.stopPropagation();
								}}>
								<IconDelete />
								<p>{t('Delete')}</p>
							</div>
						</ModalLayout>
					)}
				</div>
			</div>
			{openConfirmBox && todoToUpdate && (
				<DeleteConfirmBox
					handleDelete={handleDeleteTodo}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this todo?'
				/>
			)}
		</>
	);
};

export default TodosRowComponent;
