/* eslint-disable unicorn/no-null */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType } from '../shared/types';
import {
	TodosContract,
	TodosContractComplete,
	TodosContractCreate,
	UpdateDatePayload,
	UpdateStatePayload,
	UpdateTagPayload,
	UpdateTitlePayload,
	UpdateTodo,
} from './type';
import TodosAPI from '../../api/todo-api';
import { updateTags } from '../notes/type';
import { TagContract } from '../meetings/types';

interface TodosState {
	todos?: TodosContract[];
	todosRequestStatus: RequestStatus;

	todosAssignee?: TodosContract[];
	todosAssigneeRequestStatus: RequestStatus;

	selectMeetingTodos?: TodosContract[];
	selectMeetingTodosRequestStatus: RequestStatus;

	selectMeetingPrevTodos?: TodosContract[];
	selectMeetingPrevTodosRequestStatus: RequestStatus;

	notesTodos?: TodosContract[];
	notesTodosRequestStatus: RequestStatus;

	prevMeetingsTodos?: TodosContract[];
}

const initialState: TodosState = {
	todos: undefined,
	todosRequestStatus: createRequestStatus(RequestStatusType.New),

	todosAssignee: undefined,
	todosAssigneeRequestStatus: createRequestStatus(RequestStatusType.New),

	selectMeetingTodos: undefined,
	selectMeetingTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	selectMeetingPrevTodos: undefined,
	selectMeetingPrevTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	notesTodos: undefined,
	notesTodosRequestStatus: createRequestStatus(RequestStatusType.New),

	prevMeetingsTodos: undefined,
};

export const createTodos = createAsyncThunk(
	'todos/create',
	async (body: TodosContractCreate): Promise<TodosContractCreate> => {
		return TodosAPI.createTodo(body);
	},
);

export const getCurrentUserTodos = createAsyncThunk('todos/currentUser', async (): Promise<TodosContract[]> => {
	return TodosAPI.getCurrentUserTodos();
});

export const getCurrentUserTodosAssignee = createAsyncThunk(
	'todos/currentUser/assignee',
	async (): Promise<TodosContract[]> => {
		return TodosAPI.getCurrentUserTodosAssignee();
	},
);

export const getSelectMeetingTodos = createAsyncThunk(
	'todos/event',
	async (eventId: string): Promise<TodosContract[]> => {
		return TodosAPI.getSelectMeetingTodos(eventId);
	},
);

export const getSelectMeetingTodosPrev = createAsyncThunk(
	'todos/event/prev',
	async (eventId: string): Promise<TodosContract[]> => {
		return TodosAPI.getSelectMeetingTodos(eventId);
	},
);

export const getNotesTodos = createAsyncThunk('todos/notes', async (notesId: string): Promise<TodosContract[]> => {
	return TodosAPI.getNotesTodos(notesId);
});

export const updateTodos = createAsyncThunk(
	'todos/update',
	async ({ body, todoId }: { body: TodosContractCreate; todoId: string }): Promise<TodosContractCreate> => {
		return TodosAPI.updateTodo(body, todoId);
	},
);

export const addTags = createAsyncThunk('todos/tags/add', async (body: updateTags): Promise<TagContract> => {
	return TodosAPI.addTagsForTodos(body);
});

export const deleteTagsFromTodo = createAsyncThunk(
	'todos/tags/delete',
	async ({ todoId, tagsId }: { todoId: string; tagsId: string }): Promise<undefined> => {
		return TodosAPI.deleteTagsFromTodo(todoId, tagsId);
	},
);

export const deleteTodo = createAsyncThunk('todos/delete', async (todoId: string): Promise<undefined> => {
	return TodosAPI.deleteTodo(todoId);
});

export const completeTodos = createAsyncThunk(
	'todos/update/complete',
	async ({ body, todoId }: { body: TodosContractComplete; todoId: string }): Promise<TodosContractComplete> => {
		return TodosAPI.completeTodo(body, todoId);
	},
);

export const getOneTodo = createAsyncThunk('todos/get/one', async (todoId: string): Promise<TodosContract> => {
	return TodosAPI.getOneTodo(todoId);
});

const slice = createSlice({
	name: 'todos',
	initialState,
	reducers: {
		updateTodoTitle: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.todosAssignee) {
				const todo = state.todosAssignee.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateTodoTitleValue: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.todos) {
				const todo = state.todos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}

			if (state.selectMeetingTodos) {
				const todo = state.selectMeetingTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateTodoDate: (state, action: PayloadAction<UpdateDatePayload>) => {
			const { todoId, newDate } = action.payload;
			if (state.todos) {
				const todo = state.todos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.dueDate = newDate;
				}
			}
		},

		updateTodoTag: (state, action: PayloadAction<UpdateTagPayload>) => {
			const { todoId, tag } = action.payload;
			if (state.todos) {
				const todo = state.todos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.tags = [...todo.tags, tag];
				}
			}
		},

		updateTodoTitleSelectMeeting: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.selectMeetingTodos) {
				const todo = state.selectMeetingTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateTodoTitleNote: (state, action: PayloadAction<UpdateTitlePayload>) => {
			const { todoId, newTitle } = action.payload;
			if (state.notesTodos) {
				const todo = state.notesTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.title = newTitle;
				}
			}
		},

		updateTodoState: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { todoId, stateTodo, index, userCol } = action.payload;
			if (state.todos) {
				const todo = state.todos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.state = stateTodo;
				}

				if (todo && index !== undefined) {
					todo.orderAssignee = index.toString();
					state.todos.forEach((todoNT) => {
						const currentOrder = Number(todoNT.orderAssignee);
						if (currentOrder >= index && todoNT.state === stateTodo && todoNT.id !== todo.id) {
							todoNT.orderAssignee = (currentOrder + 1).toString();
						}
					});
				}

				if (todo) {
					todo.columnUserId = userCol ? userCol : null;
				}
			}
		},

		updateTodoStateSelectMeeting: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { todoId, stateTodo } = action.payload;
			if (state.selectMeetingTodos) {
				const todo = state.selectMeetingTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.state = stateTodo;
				}
			}
		},

		updateTodoStateNote: (state, action: PayloadAction<UpdateStatePayload>) => {
			const { todoId, stateTodo } = action.payload;
			if (state.notesTodos) {
				const todo = state.notesTodos.find((todoNT) => todoNT.id === todoId);
				if (todo) {
					todo.state = stateTodo;
				}
			}
		},

		updateTodo: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.todos) {
				state.todos = state.todos.map((todo) =>
					todo.id === todoId ? { ...todo, id: newTodo.id, createdOn: todo.createdOn } : todo,
				);
			}
		},

		updateTodoAssignee: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.todos) {
				const todoIndex = state.todos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.todos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.todos = updatedTodos;
				}
			}

			if (state.selectMeetingTodos) {
				const todoIndex = state.selectMeetingTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.selectMeetingTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.selectMeetingTodos = updatedTodos;
				}
			}
		},

		updateTodoSelectMeeting: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.selectMeetingTodos) {
				const todoIndex = state.selectMeetingTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.selectMeetingTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.selectMeetingTodos = updatedTodos;
				}
			}
		},

		updateTodoSelectMeetingInMeeting: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.selectMeetingTodos) {
				const todoIndex = state.selectMeetingTodos.findIndex(
					(todo) => todo.createdOn === newTodo.createdOn && todo.title === newTodo.title,
				);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.selectMeetingTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.selectMeetingTodos = updatedTodos;
				}
			}
		},

		updateTodoSelectMeetingPrev: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.selectMeetingPrevTodos) {
				const todoIndex = state.selectMeetingPrevTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.selectMeetingPrevTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.selectMeetingPrevTodos = updatedTodos;
				}
			}
		},

		updateTodoSelectMeetingPrevInMeeting: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.selectMeetingPrevTodos) {
				const todoIndex = state.selectMeetingPrevTodos.findIndex(
					(todo) => todo.createdOn === newTodo.createdOn && todo.title === newTodo.title,
				);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.selectMeetingPrevTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.selectMeetingPrevTodos = updatedTodos;
				}
			}
		},

		updateTodoNotes: (state, action: PayloadAction<UpdateTodo>) => {
			const { todoId, newTodo } = action.payload;
			if (state.notesTodos) {
				const todoIndex = state.notesTodos.findIndex((todo) => todo.id === todoId);
				if (todoIndex !== -1) {
					const updatedTodos = [...state.notesTodos];
					updatedTodos[todoIndex] = {
						...updatedTodos[todoIndex],
						...newTodo,
					};

					state.notesTodos = updatedTodos;
				}
			}
		},

		addTodosAssignee: (state, action: PayloadAction<any>) => {
			const newTodo = action.payload;
			if (state.todos) {
				state.todos.push(newTodo);
			}
		},

		deleteTodosAssignee: (state, action: PayloadAction<string>) => {
			const todoId = action.payload;
			if (state.todos) {
				state.todos = state.todos.filter((todo) => todo.id !== todoId);
			}
		},

		addTodosSelectedMeeting: (state, action: PayloadAction<any>) => {
			const newTodo = action.payload;
			if (state.selectMeetingTodos) {
				state.selectMeetingTodos.push(newTodo);
			}
		},

		deleteTodosSelectedMeeting: (state, action: PayloadAction<string>) => {
			const todoId = action.payload;
			if (state.selectMeetingTodos) {
				state.selectMeetingTodos = state.selectMeetingTodos.filter((todo) => todo.id !== todoId);
			}
		},

		addTodosNotes: (state, action: PayloadAction<any>) => {
			const newTodo = action.payload;
			if (state.notesTodos) {
				state.notesTodos.push(newTodo);
			}
		},

		deleteTodosNotes: (state, action: PayloadAction<string>) => {
			const todoId = action.payload;
			if (state.notesTodos) {
				state.notesTodos = state.notesTodos.filter((todo) => todo.id !== todoId);
			}
		},

		sortTodosAssignee: (state) => {
			if (state.todos) {
				state.todos.sort((b, a) => {
					const aDate = a.dueDate || a.meetingStartDate || a.createdOn;
					const bDate = b.dueDate || b.meetingStartDate || b.createdOn;

					if (aDate && bDate) {
						return new Date(aDate).getTime() - new Date(bDate).getTime();
					} else if (aDate) {
						return -1;
					} else if (bDate) {
						return 1;
					} else {
						return 0;
					}
				});
			}
		},

		reverseTodos: (state) => {
			if (state.todos) state.todos = [...state.todos].reverse();
		},

		setPrevMeetingsTodos: (state, action: PayloadAction<TodosContract[] | undefined>) => {
			console.log(action.payload);
			state.prevMeetingsTodos = action.payload;
		},
	},
	extraReducers(builder): void {
		builder.addCase(createTodos.fulfilled, (state, action) => {
			state.todosRequestStatus = createRequestStatus(RequestStatusType.Success);
		});
		builder.addCase(createTodos.rejected, (state, action) => {
			state.todosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getCurrentUserTodos.pending, (state) => {
			state.todosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCurrentUserTodos.fulfilled, (state, action) => {
			state.todosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.todos = action.payload;
		});
		builder.addCase(getCurrentUserTodos.rejected, (state, action) => {
			state.todosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getCurrentUserTodosAssignee.pending, (state) => {
			state.todosAssigneeRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCurrentUserTodosAssignee.fulfilled, (state, action) => {
			state.todosAssigneeRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.todosAssignee = action.payload;
		});
		builder.addCase(getCurrentUserTodosAssignee.rejected, (state, action) => {
			state.todosAssigneeRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSelectMeetingTodos.pending, (state) => {
			state.selectMeetingTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSelectMeetingTodos.fulfilled, (state, action) => {
			state.selectMeetingTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.selectMeetingTodos = action.payload;
		});
		builder.addCase(getSelectMeetingTodos.rejected, (state, action) => {
			state.selectMeetingTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getSelectMeetingTodosPrev.pending, (state) => {
			state.selectMeetingPrevTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getSelectMeetingTodosPrev.fulfilled, (state, action) => {
			state.selectMeetingPrevTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.selectMeetingPrevTodos = action.payload;
		});
		builder.addCase(getSelectMeetingTodosPrev.rejected, (state, action) => {
			state.selectMeetingPrevTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getNotesTodos.pending, (state) => {
			state.notesTodosRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotesTodos.fulfilled, (state, action) => {
			state.notesTodosRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.notesTodos = action.payload;
		});
		builder.addCase(getNotesTodos.rejected, (state, action) => {
			state.notesTodosRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const {
	updateTodoTitle,
	updateTodoTitleValue,
	updateTodoDate,
	updateTodoTag,
	updateTodoTitleSelectMeeting,
	updateTodoTitleNote,
	updateTodoState,
	updateTodoStateNote,
	updateTodoStateSelectMeeting,
	addTodosAssignee,
	deleteTodosAssignee,
	addTodosSelectedMeeting,
	deleteTodosSelectedMeeting,
	addTodosNotes,
	deleteTodosNotes,
	sortTodosAssignee,
	reverseTodos,
	updateTodo,
	updateTodoAssignee,
	updateTodoNotes,
	updateTodoSelectMeeting,
	updateTodoSelectMeetingPrev,
	updateTodoSelectMeetingInMeeting,
	updateTodoSelectMeetingPrevInMeeting,
	setPrevMeetingsTodos,
} = actions;

export default reducer;
