import React from 'react';
import styles from './sidebar.module.scss';
import { RouteUrls } from '../../../routes/routes-config';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../images/logo/Logo_Fivedays.png';
import { useAppDispatch } from '../../../store/hooks';
import { IconGroupS, IconMeetingsSidebar, IconNotes, IconProject, IconTodosSidebar } from '../../utils/icon';
import { setSelectedMeeting } from '../../../store/meetings/slice';
import { getCurrentUserTodosAssignee } from '../../../store/todos/slice';
import TooltipIcon from '../tooltip/tooltip-icon.component';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const activeLink = styles.icon_nav_active;
	const normalLink = styles.icon;

	const handleDate = () => {
		dispatch(setSelectedMeeting(undefined));
	};

	return (
		<nav className={styles.sidebar}>
			<div className={styles.sidebarTop}>
				<div className={styles.logo} style={{ paddingBlock: window.api && window.api.isElectron ? '8px' : '' }}>
					<img
						style={{ width: window.api && window.api.isElectron ? '20px' : '' }}
						src={Logo}
						alt='Logo Fivedays'
					/>
				</div>

				{/* <NavLink to={RouteUrls.Home} className={({ isActive }) => (isActive ? activeLink : normalLink)}>
					<IconHome />
					<p>{t('Home')}</p>
				</NavLink> */}
				<div className='meetings' data-tooltip-id={'Meetings'} data-tooltip-content={t('Meetings').toString()}>
					<NavLink
						replace
						to={RouteUrls.Meetings}
						onClick={() => handleDate()}
						className={({ isActive }) => (isActive ? activeLink : normalLink)}>
						<IconGroupS />
					</NavLink>
				</div>

				<div className='calendar' data-tooltip-id={'Calendar'} data-tooltip-content={t('Calendar').toString()}>
					<NavLink
						replace
						to={RouteUrls.Calendars}
						className={({ isActive }) => (isActive ? activeLink : normalLink)}>
						<IconMeetingsSidebar />
					</NavLink>
				</div>

				<div className='todos' data-tooltip-id={'Todos'} data-tooltip-content={t('Todos').toString()}>
					<NavLink
						replace
						to={RouteUrls.Todos}
						onClick={() => dispatch(getCurrentUserTodosAssignee())}
						className={({ isActive }) => (isActive ? activeLink : normalLink)}>
						<IconTodosSidebar />
					</NavLink>
				</div>

				<div className='notes' data-tooltip-id={'Notes'} data-tooltip-content={t('Notes').toString()}>
					<NavLink
						replace
						to={RouteUrls.Notes}
						className={({ isActive }) => (isActive ? activeLink : normalLink)}>
						<IconNotes />
					</NavLink>
				</div>

				<div className='project' data-tooltip-id={'Projects'} data-tooltip-content={t('Projects').toString()}>
					<NavLink
						replace
						to={RouteUrls.Project}
						className={({ isActive }) => (isActive ? activeLink : normalLink)}>
						<IconProject />
					</NavLink>
				</div>
			</div>

			<TooltipIcon id={'Meetings'} title={t('Meetings')} placement='right' />
			<TooltipIcon id={'Calendar'} title={t('Calendar')} placement='right' />
			<TooltipIcon id={'Todos'} title={t('Todos')} placement='right' />
			<TooltipIcon id={'Notes'} title={t('Notes')} placement='right' />
			<TooltipIcon id={'Projects'} title={t('Projects')} placement='right' />
		</nav>
	);
};

export default Sidebar;
