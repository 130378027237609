import React, { useRef, useState } from 'react';
import { TodosContract, UpdateTodo } from '../../../store/todos/type';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import TagModal from '../../../shared/components/modals/tagModal/tag-modal.component';
import Tags from '../../../shared/components/tags/tags.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TagContract } from '../../../store/meetings/types';
import { addTags, deleteTagsFromTodo } from '../../../store/todos/slice';
import styles from '../todos.module.scss';
import { PayloadAction } from '@reduxjs/toolkit';
import { SectionType } from '../../../shared/hooks/use-todos-mutations.hook';

interface Props {
	todo: TodosContract;
	updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>;
	sectionType: SectionType;
}

const TagsCell: React.FC<Props> = ({ todo, updateFn, sectionType }) => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);

	const [openTagMenu, setOpenTagMenu] = useState<boolean>(false);

	const tagModalRef = useRef<HTMLDivElement | null>(null);
	useClickOutside(tagModalRef, () => setOpenTagMenu(false));

	const handleDeleteTag = (tags: TagContract) => {
		const body = {
			...todo,
			tags: todo.tags.filter((tag) => tag.id !== tags.id),
		};

		dispatch(updateFn({ todoId: todo.id, newTodo: body }));
		dispatch(deleteTagsFromTodo({ todoId: todo.id, tagsId: tags.id ?? '' }));
	};

	const handleTags = (tag: string, color: string) => {
		if (todo) {
			const body = {
				tag,
				color,
				todoId: todo.id,
			};

			dispatch(addTags(body)).then((res) => {
				const newTag = res.payload as TagContract;
				const updatedTodo = {
					...todo,
					tags: [...todo.tags, newTag],
				};
				dispatch(updateFn({ todoId: todo.id, newTodo: updatedTodo }));
			});
			setOpenTagMenu(false);
		}
	};

	return (
		<div className={styles.todoRowColumn} ref={tagModalRef}>
			<div className={styles.todoRowColumnContent} onClick={() => setOpenTagMenu(!openTagMenu)}>
				{todo.tags?.map((tag: TagContract, indexTodoTag) => (
					<Tags key={indexTodoTag} tag={tag} />
				))}
			</div>
			{openTagMenu && (
				<ModalLayout width='250px' top='0'>
					<TagModal
						sectionType={sectionType}
						handleDeleteTag={handleDeleteTag}
						handleTags={handleTags}
						tags={todo.tags}
					/>
				</ModalLayout>
			)}
		</div>
	);
};

export default TagsCell;
