import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { UserRoleFivedays } from '../../store/user/type';
import { getAllCompanies, restoreTodosOrder } from '../../store/settings/slice';
import FivedaysCompaniesComponent from './components/fivedays-companies.component';
import styles from './setting-fivedays.module.scss';

const SettingsFivedaysPage = () => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, []);

	React.useEffect(() => {
		if (user && user.roleFivedays === UserRoleFivedays.SUPERADMIN) {
			dispatch(getAllCompanies());
		}
	}, [user]);

	const restoreOrderForTodos = () => {
		dispatch(restoreTodosOrder());
	};

	return (
		<div className={styles.container}>
			<FivedaysCompaniesComponent />

			{/* <div className={styles.buttonRestore}>
				<button onClick={() => restoreOrderForTodos()}>RESTORE TODOS ORDER</button>
			</div> */}
		</div>
	);
};

export default SettingsFivedaysPage;
