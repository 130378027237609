import React, { PropsWithChildren } from 'react';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { authInstance } from '../app';
import { AuthenticationProvider } from './types/authentication-provider';

export function UnauthTemplate({ children }: PropsWithChildren): React.ReactElement | null {
	const instance = authInstance;

	if (instance.authProvider === AuthenticationProvider.Microsoft) {
		return <UnauthenticatedTemplate>{children}</UnauthenticatedTemplate>;
	} else if (instance.authProvider === AuthenticationProvider.Google) {
		return !instance.googleAuthInstance().isAuthenticated ? <React.Fragment>{children}</React.Fragment> : null;
	}
	return null;
}
