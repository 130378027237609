import React, { useRef, useState } from 'react';
import styles from '../todos.module.scss';
import { TodoAttendee, TodosContract, UpdateTodo } from '../../../store/todos/type';
import { Avatar } from '../../../shared/utils/avatar';
import { PersonaSize } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import AttendeesInput from '../../../shared/components/input/attendees-input.component';
import { PayloadAction } from '@reduxjs/toolkit';
import { Items } from '../../../shared/components/input/input.component';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';

interface Props {
    todo: TodosContract,
    attendees?: TodoAttendee[],
    updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>,
}

const AttendeeCell: React.FC<Props> = ({ todo, updateFn, attendees }) => {
    const { t } = useTranslation();
    const uuid = Math.random() * 10000;
    const [openModal, setOpenModal] = useState<boolean>(false);

    const attendeeRef = useRef<HTMLDivElement | null>(null);

    useClickOutside(attendeeRef, () => setOpenModal(false));

    const updateAttendeeTodoMutation = useUpdateTodoMutation();

    const handleAttendee = (attendee: Items | undefined) => {

        const body = {
            ...todo,
            assigneeName: attendee?.title ?? '',
            assigneeDisplayName: attendee?.subTitle ?? '',
        };

        updateAttendeeTodoMutation.mutate({ id: body.id, newTodo: body, oldTodo: todo, updateFn });
        setOpenModal(false);
    };

    return (
        <div ref={attendeeRef} className={styles.todoRowColumn}>
            <div className={styles.todoRowColumnContent} onClick={() => setOpenModal(!openModal)}>
                {todo.assigneeDisplayName === null ? (
                    <span>{t('-')}</span>
                ) : (
                    <div>
                        <Avatar
                            size={PersonaSize.size28}
                            name={todo.assigneeName}
                            mail={todo.assigneeDisplayName}
                            index={uuid}
                        />
                    </div>
                )}
            </div>
            {openModal && (
                <ModalLayout top='100%' width='300px'>
                    <div className={styles.modalAttendeeContainer}>
                        <AttendeesInput
                            renderModal={attendees?.map((attendee) => ({
                                title: `${attendee.userName}`,
                                leading: <Avatar size={PersonaSize.size28} name={attendee.userName} mail={attendee.email} index={uuid} />,
                                subTitle: `${attendee.email}`
                            }))}
                            getValue={handleAttendee}
                            placeHolder={''}
                            isLoading={false}
                            renderPlaceHolder={false}
                            defaultValue={({
                                title: `${todo.assigneeName}`,
                            })}
                        />
                    </div>
                </ModalLayout>
            )}
        </div>
    );
};

export default AttendeeCell;