export const emoticonsUT8 = [
	// **Smiley Faces and Emotions**
	{ code: '&#128512;', smiley: '😀' },
	{ code: '&#128519;', smiley: '😇' },
	{ code: '&#128520;', smiley: '😈' },
	{ code: '&#128525;', smiley: '😍' },
	{ code: '&#128526;', smiley: '😎' },
	{ code: '&#129395;', smiley: '🥳' },
	{ code: '&#128568;', smiley: '😸' },
	{ code: '&#128578;', smiley: '🙃' },
	{ code: '&#128579;', smiley: '🙂' },
	{ code: '&#128580;', smiley: '🙄' },
	{ code: '&#128581;', smiley: '😌' },
	{ code: '&#128582;', smiley: '😒' },
	{ code: '&#128583;', smiley: '😓' },
	{ code: '&#128584;', smiley: '😔' },
	{ code: '&#128585;', smiley: '😕' },
	{ code: '&#128586;', smiley: '😖' },
	{ code: '&#128587;', smiley: '😗' },
	{ code: '&#128588;', smiley: '😘' },
	{ code: '&#128591;', smiley: '😛' },
	{ code: '&#128594;', smiley: '😞' },
	{ code: '&#128595;', smiley: '😟' },
	{ code: '&#128596;', smiley: '😠' },
	{ code: '&#128597;', smiley: '😡' },
	{ code: '&#128598;', smiley: '😢' },
	{ code: '&#128599;', smiley: '😭' },
	{ code: '&#128600;', smiley: '😤' },
	{ code: '&#128601;', smiley: '😥' },
	{ code: '&#128602;', smiley: '😮' },
	{ code: '&#128605;', smiley: '😳' },

	// **Work and Office**
	{ code: '&#128188;', smiley: '💼' },
	{ code: '&#128204;', smiley: '📌' },
	{ code: '&#128214;', smiley: '📖' },
	{ code: '&#128222;', smiley: '📞' },
	{ code: '&#128267;', smiley: '🔋' },
	{ code: '&#128241;', smiley: '📱' },
	{ code: '&#128246;', smiley: '📶' },
	{ code: '&#128386;', smiley: '🖂' },
	{ code: '&#9889;', smiley: '⚡' },
	{ code: '&#11088;', smiley: '⭐' },
	{ code: '&#9749;', smiley: '☕' },
	{ code: '&#8987;', smiley: '⌛' },
	{ code: '&#127942;', smiley: '🏆' },
	{ code: '&#127919;', smiley: '🎯' },
	{ code: '&#129513;', smiley: '🧩' },
	{ code: '&#127757;', smiley: '🌍' },
	{ code: '&#127760;', smiley: '🌐' },
	{ code: '&#127909;', smiley: '🎥' },
	{ code: '&#128226;', smiley: '📢' },
	{ code: '&#128507;', smiley: '🗻' },
	{ code: '&#128508;', smiley: '🗼' },
	{ code: '&#128640;', smiley: '🚀' },
	{ code: '&#128681;', smiley: '🚩' },
	{ code: '&#128735;', smiley: '🛟' },
	{ code: '&#128736;', smiley: '🛠' },
	{ code: '&#127793;', smiley: '🌱' },
	{ code: '&#127809;', smiley: '🍁' },
	{ code: '&#127800;', smiley: '🌸' },
	{ code: '&#128270;', smiley: '🔎' },
	{ code: '&#128273;', smiley: '🔑' },
	{ code: '&#128294;', smiley: '🔦' },
	{ code: '&#128295;', smiley: '🔧' },
	{ code: '&#129527;', smiley: '🧷' },

	// **Technology and Gadgets**
	{ code: '&#128280;', smiley: '🔍' },
	{ code: '&#128194;', smiley: '📄' },
	{ code: '&#128290;', smiley: '📀' },
	{ code: '&#128463;', smiley: '🗂' },
	{ code: '&#128426;', smiley: '🖋️' },
	{ code: '&#128400;', smiley: '🖥️' },
	{ code: '&#128375;', smiley: '🔒' },
	{ code: '&#128213;', smiley: '📋' },
	{ code: '&#128205;', smiley: '📡' },
	{ code: '&#128235;', smiley: '📼' },
	{ code: '&#128299;', smiley: '💿' },
	{ code: '&#128406;', smiley: '📷' },
	{ code: '&#128241;', smiley: '📱' },
	{ code: '&#128189;', smiley: '📊' },
	{ code: '&#128206;', smiley: '📡' },

	// **Nature and Environment**
	{ code: '&#128051;', smiley: '🐆' },
	{ code: '&#128013;', smiley: '🐍' },
	{ code: '&#128010;', smiley: '🐐' },
	{ code: '&#128021;', smiley: '🐕' },
	{ code: '&#128017;', smiley: '🐑' },
	{ code: '&#128035;', smiley: '🐓' },
	{ code: '&#128049;', smiley: '🐩' },
	{ code: '&#128016;', smiley: '🐐' },
	{ code: '&#128054;', smiley: '🐴' },
	{ code: '&#127804;', smiley: '🍳' },
	{ code: '&#127822;', smiley: '🍓' },
	{ code: '&#127832;', smiley: '🍫' },

	// **Transportation**
	{ code: '&#128690;', smiley: '🚃' },
	{ code: '&#128674;', smiley: '🚂' },
	{ code: '&#128665;', smiley: '🚉' },
	{ code: '&#128679;', smiley: '🚙' },
	{ code: '&#128666;', smiley: '🚎' },
	{ code: '&#128668;', smiley: '🚍' },
	{ code: '&#128659;', smiley: '🚚' },
	{ code: '&#128681;', smiley: '🚩' },
	{ code: '&#128728;', smiley: '🚁' },

	// **Sports and Games**
	{ code: '&#127942;', smiley: '🏆' },
	{ code: '&#127941;', smiley: '🏅' },
	{ code: '&#127925;', smiley: '🎮' },
	{ code: '&#127927;', smiley: '🎲' },
	{ code: '&#127919;', smiley: '🎯' },
	{ code: '&#127934;', smiley: '🥊' },
	{ code: '&#127940;', smiley: '🏓' },
	{ code: '&#127934;', smiley: '🥋' },
	{ code: '&#127925;', smiley: '🎮' },

	// **Miscellaneous**
	{ code: '&#128152;', smiley: '💖' },
	{ code: '&#128153;', smiley: '💗' },
	{ code: '&#128154;', smiley: '💙' },
	{ code: '&#128155;', smiley: '💚' },
	{ code: '&#128156;', smiley: '💛' },
	{ code: '&#128157;', smiley: '💜' },
	{ code: '&#128158;', smiley: '💝' },
	{ code: '&#128159;', smiley: '💞' },
	{ code: '&#128160;', smiley: '💟' },
	{ code: '&#128162;', smiley: '💠' },

	// **Food and Drink**
	{ code: '&#127830;', smiley: '🍰' },
	{ code: '&#127848;', smiley: '🍞' },
	{ code: '&#127836;', smiley: '🍩' },
	{ code: '&#127842;', smiley: '🍫' },
	{ code: '&#127850;', smiley: '🍲' },
	{ code: '&#127852;', smiley: '🍶' },
	{ code: '&#127844;', smiley: '🍔' },
	{ code: '&#127849;', smiley: '🍴' },
	{ code: '&#127857;', smiley: '🍇' },
	{ code: '&#127831;', smiley: '🍎' },
];
