import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './file-update.module.scss';
import { AccessRightType, FileContract, MeetingContract } from '../../../store/meetings/types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { extractDomainWithExt } from '../../utils/domainext';
import { updateFileAccessRightSuccess, updateMeetingFile } from '../../../store/meetings/slice';
import {
	addPeopleShareFileShared,
	deletePeopleFileShared,
	deletePeopleShareFileShare,
	getFilesForAMeetingShared,
	postPeopleShareFileShare,
	updateMeetingFileShared,
} from '../../../store/sharing/slice';
import {
	addPeopleShareFile,
	deletePeopleFile,
	deletePeopleShareFile,
	postPeopleShareFile,
	updateProjectFile,
	updateProjectFiles,
} from '../../../store/project/slice';
import { People } from '../../../store/notes/type';
import { IconClose, IconContact, IconEdit, IconGlobe, IconLock, IconMeetingsPage } from '../../utils/icon';
import { Avatar } from '../../utils/avatar';
import AttendeesInput, { Items } from '../input/attendees-input.component';
import { PersonaSize } from '@fluentui/react';

interface props {
	file: FileContract;
	userId: string;
	selectedMeeting?: MeetingContract;
	mail: string;
	close: (index: number) => void;
}

const UpdateFilesModal: React.FC<React.PropsWithChildren<props>> = ({ file, userId, selectedMeeting, mail, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const closeModal = (index: number) => {
		setAnimation(true);
		setTimeout(() => {
			close(index);
			setAnimation(false);
		}, 300);
	};

	const { user } = useAppSelector((state) => state.user);
	const { filesPeople, selectProject, projectsSharePeople } = useAppSelector((state) => state.project);
	const { filesPeopleShare } = useAppSelector((state) => state.sharing);

	const domain = extractDomainWithExt(mail);

	const [selectedAccessType, setSelectedAccessType] = React.useState<AccessRightType>(file.accessRightType);
	const [companiesAccess, setcompaniesAccess] = React.useState<string[]>([domain ? domain : '']);
	const [name, setName] = React.useState(file.displayName);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (AccessRightType) {
			event.preventDefault();
			const body = {
				accessRightType: selectedAccessType,
				accessRightCompanies: companiesAccess,
				displayName: name,
			};
			if (file) {
				if (user) {
					if (selectedMeeting) {
						dispatch(
							updateMeetingFile({
								body,
								selectedMeetingID: selectedMeeting.iCalUId,
								fileId: file?.id,
							}),
						).then(() => {
							close(-1);
							dispatch(
								updateFileAccessRightSuccess({
									fileId: file?.id,
									accessRightTypeSelect: selectedAccessType,
									accessRightCompanies: companiesAccess,
									displayName: name,
								}),
							);
						});
					} else {
						if (file && user && selectProject) {
							dispatch(
								updateProjectFile({
									body,
									projectId: selectProject.id,
									fileId: file?.id,
								}),
							).then((res) => {
								dispatch(updateProjectFiles(res.payload as FileContract));
								close(-1);
							});
						}
					}
				} else {
					if (selectedMeeting) {
						dispatch(
							updateMeetingFileShared({
								body,
								selectedMeetingID: selectedMeeting.iCalUId,
								fileId: file?.id,
								userId,
							}),
						).then(() => {
							close(-1);
							dispatch(getFilesForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId: mail }));
						});
					}
				}
			}
		}
	};

	const handleCheckboxChange = (accessData: AccessRightType) => {
		const value = accessData;
		if (
			value === AccessRightType.ONLYME ||
			value === AccessRightType.EVERYONE ||
			value === AccessRightType.SHARED ||
			value === AccessRightType.INTERN
		) {
			setSelectedAccessType(value);
		}
	};

	// --------

	const [openMenu, setOpenMenu] = React.useState(false);
	const handleOpenMenu = () => {
		setOpenMenu(!openMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleOpenMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleOpenMenu]);

	// --

	const [selectedAddresses, setSelectedAddresses] = React.useState<People[]>(filesPeople ? filesPeople : []);

	// const handleCheckboxChangePeople = (attendee: People) => {
	// 	setSelectedAddresses([...selectedAddresses, attendee]);

	// 	const body = {
	// 		id: '1',
	// 		username: attendee.username,
	// 		mail: attendee.mail,
	// 	};

	// 	if (file) {
	// 		if (user) {
	// 			dispatch(postPeopleShareFile({ body, fileId: file.id })).then((res) => {
	// 				dispatch(addPeopleShareFile(res.payload as People));
	// 			});
	// 		} else {
	// 			dispatch(postPeopleShareFileShare({ body, fileId: file.id })).then((res) => {
	// 				dispatch(addPeopleShareFileShared(res.payload as People));
	// 			});
	// 		}
	// 	}
	// };

	const handleAttendee = (attendee: Items | undefined) => {
		if (attendee) {
			setSelectedAddresses([
				...selectedAddresses,
				{
					id: attendee.id ? attendee.id : attendee.title,
					username: attendee.title,
					mail: attendee.id ? attendee.id : attendee.title,
				},
			]);

			const body = {
				id: '1',
				username: attendee.title,
				mail: attendee.id ? attendee.id : attendee.title,
			};

			if (file) {
				if (user) {
					dispatch(postPeopleShareFile({ body, fileId: file.id })).then((res) => {
						dispatch(addPeopleShareFile(res.payload as People));
					});
				} else {
					dispatch(postPeopleShareFileShare({ body, fileId: file.id })).then((res) => {
						dispatch(addPeopleShareFileShared(res.payload as People));
					});
				}
			}
		}
	};

	const handleDeletePeopleShare = (mailPeople: string) => {
		if (file) {
			if (user) {
				dispatch(deletePeopleShareFile({ fileId: file.id, mail: mailPeople })).then(() => {
					dispatch(deletePeopleFile(mailPeople));
					setSelectedAddresses(selectedAddresses.filter((m) => m.mail !== mailPeople));
				});
			} else {
				dispatch(deletePeopleShareFileShare({ fileId: file.id, mail: mailPeople })).then(() => {
					dispatch(deletePeopleFileShared(mailPeople));
					setSelectedAddresses(selectedAddresses.filter((m) => m.mail !== mailPeople));
				});
			}
		}
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.titleWrapper}>
						<h3>
							<IconEdit />
							{t('Update access')}
						</h3>
						<div className={styles.closeButton}>
							<div
								className={styles.icon}
								onClick={() => {
									closeModal(-1);
								}}>
								<IconClose />
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div>
							<input
								type='text'
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder={t('Update the title of your file') as string}
							/>
						</div>
						<div className={styles.flexAccess}>
							<div
								className={
									selectedAccessType === AccessRightType.EVERYONE
										? styles.selectAccess
										: styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.EVERYONE)}>
								<IconGlobe />
								{t('Public')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.INTERN ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.INTERN)}>
								<IconContact />
								{t('Intern')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.SHARED ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.SHARED)}>
								<IconMeetingsPage />
								{t('Shared')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.ONLYME ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.ONLYME)}>
								<IconLock />
								{t('Private')}
							</div>
						</div>

						<br />
						{selectedAccessType === AccessRightType.SHARED && (
							<>
								{selectedMeeting && user && (
									<AttendeesInput
										isLoading={isLoading}
										placeHolder={t('Invite people') as string}
										attendees={selectedAddresses
											.filter((attendee) => attendee.mail !== user?.email)
											.map((attendee) => ({
												type: 'required',
												emailAddress: {
													name: attendee.username ?? '',
													address: attendee.mail ?? '',
												},
											}))}
										resetFieldOnSelect={true}
										getValue={handleAttendee}
										handleRemoveAttendee={handleDeletePeopleShare}
										renderModal={selectedMeeting.attendees
											.filter(
												(attendee) =>
													!filesPeople?.some(
														(sharedPeople) =>
															sharedPeople.mail === attendee.emailAddress.address,
													),
											)
											.filter(
												(mergedAttendee) =>
													!selectedAddresses.some(
														(attendee) =>
															attendee.mail === mergedAttendee.emailAddress.address,
													),
											)
											.map((attendee, index) => ({
												leading: (
													<Avatar
														size={PersonaSize.size32}
														name={attendee.emailAddress.name}
														mail={attendee.emailAddress.address}
														index={index}
													/>
												),
												id: attendee.emailAddress.address,
												title: attendee.emailAddress.name,
												subTitle: attendee.emailAddress.address,
											}))}
									/>
								)}

								{selectedMeeting && !user && (
									<AttendeesInput
										isLoading={isLoading}
										placeHolder={t('Invite people') as string}
										attendees={selectedAddresses
											.filter((attendee) => attendee.mail !== mail)
											.map((attendee) => ({
												type: 'required',
												emailAddress: {
													name: attendee.username ?? '',
													address: attendee.mail ?? '',
												},
											}))}
										resetFieldOnSelect={true}
										getValue={handleAttendee}
										handleRemoveAttendee={handleDeletePeopleShare}
										renderModal={selectedMeeting.attendees
											.filter(
												(attendee) =>
													!filesPeopleShare?.some(
														(sharedPeople) =>
															sharedPeople.mail === attendee.emailAddress.address,
													),
											)
											.filter(
												(mergedAttendee) =>
													!selectedAddresses.some(
														(attendee) =>
															attendee.mail === mergedAttendee.emailAddress.address,
													),
											)
											.map((attendee, index) => ({
												leading: (
													<Avatar
														size={PersonaSize.size32}
														name={attendee.emailAddress.name}
														mail={attendee.emailAddress.address}
														index={index}
													/>
												),
												id: attendee.emailAddress.address,
												title: attendee.emailAddress.name,
												subTitle: attendee.emailAddress.address,
											}))}
									/>
								)}

								{!selectedMeeting && (
									<>
										{selectProject &&
											user &&
											selectProject.accessRightType === AccessRightType.INTERN && (
												<AttendeesInput
													isLoading={isLoading}
													placeHolder={t('Invite people') as string}
													attendees={selectedAddresses
														.filter((attendee) => attendee.mail !== user?.email)
														.map((attendee) => ({
															type: 'required',
															emailAddress: {
																name: attendee.username ?? '',
																address: attendee.mail ?? '',
															},
														}))}
													resetFieldOnSelect={true}
													getValue={handleAttendee}
													handleRemoveAttendee={handleDeletePeopleShare}
													renderModal={user.userCompany
														.filter(
															(attendee) =>
																!filesPeople?.some(
																	(sharedPeople) =>
																		sharedPeople.mail === attendee.userName,
																),
														)
														.filter(
															(mergedAttendee) =>
																!selectedAddresses.some(
																	(attendee) =>
																		attendee.mail === mergedAttendee.userName,
																),
														)
														.map((attendee, index) => ({
															leading: (
																<Avatar
																	size={PersonaSize.size32}
																	name={attendee.name}
																	mail={attendee.userName}
																	index={index}
																/>
															),
															id: attendee.userName,
															title: attendee.name,
															subTitle: attendee.userName,
														}))}
												/>
											)}

										{selectProject &&
											user &&
											selectProject.accessRightType !== AccessRightType.INTERN &&
											projectsSharePeople && (
												<AttendeesInput
													isLoading={isLoading}
													placeHolder={t('Invite people') as string}
													attendees={selectedAddresses
														.filter((attendee) => attendee.mail !== user?.email)
														.map((attendee) => ({
															type: 'required',
															emailAddress: {
																name: attendee.username ?? '',
																address: attendee.mail ?? '',
															},
														}))}
													resetFieldOnSelect={true}
													getValue={handleAttendee}
													handleRemoveAttendee={handleDeletePeopleShare}
													renderModal={projectsSharePeople
														.filter(
															(attendee) =>
																!filesPeople?.some(
																	(sharedPeople) =>
																		sharedPeople.mail === attendee.mail,
																),
														)
														.filter(
															(mergedAttendee) =>
																!selectedAddresses.some(
																	(attendee) => attendee.mail === mergedAttendee.mail,
																),
														)
														.map((attendee, index) => ({
															leading: (
																<Avatar
																	size={PersonaSize.size32}
																	name={attendee.username}
																	mail={attendee.mail}
																	index={index}
																/>
															),
															id: attendee.mail,
															title: attendee.username,
															subTitle: attendee.mail,
														}))}
												/>
											)}
									</>
								)}
							</>
						)}
						<br />

						<button className={styles.button} type='submit'>
							{t('Confirm')}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default UpdateFilesModal;
