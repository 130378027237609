import React, { forwardRef, ReactNode, Ref } from 'react';
import styles from './modal-layout.module.scss';

interface Props {
    children: ReactNode,
    right?: string,
    left?: string,
    top?: string,
    bottom?: string,
    padding?: string,
    width?: string,
}

const ModalLayout = forwardRef(({ children, left, right, bottom, padding, top, width }: Props, ref: Ref<HTMLDivElement>) => {
    return (
        <div
            ref={ref}
            className={styles.modalContainer}
            style={{ left, right, top, bottom, padding, width }}
        >
            {children}
        </div>
    );
});

ModalLayout.displayName = 'ModalLayout';

export default ModalLayout;