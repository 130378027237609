/* eslint-disable quotes */
import { useMutation } from '@tanstack/react-query';
import { TodosContract, UpdateTodo } from '../../store/todos/type';
import TodosAPI from '../../api/todo-api';
import { GeneratorsUtil } from '../utils/generators.util';
import { useAppDispatch } from '../../store/hooks';
import { addTodosAssignee, deleteTodosAssignee, updateTodo } from '../../store/todos/slice';
import { toast } from '../components/modals/toast/toast-manager';
import { useTranslation } from 'react-i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { addTodoProject, getTodosProject, updateTodoFromProject, updateTodoProject } from '../../store/project/slice';

export enum SectionType {
	meeting,
	todos,
	project,
}

type TodoMutation = {
	id: string;
	newTodo: TodosContract;
	oldTodo: TodosContract;
	updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>;
};

export const useCreateTodoMutation = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	return useMutation({
		mutationFn: async (newTodo: TodosContract) => {
			return TodosAPI.createTodo(newTodo);
		},
		onMutate: async (newTodo: TodosContract) => {
			const tempId = `temp-${GeneratorsUtil.generateId(16)}`;

			dispatch(addTodosAssignee({ ...newTodo, id: tempId, createdOn: new Date().toISOString() }));

			// if (newTodo.projectId) {
			// 	dispatch(addTodoProject({ ...newTodo, id: tempId, createdOn: new Date().toISOString() }));
			// }

			return { tempId };
		},
		onError: (error: unknown, newTodo: TodosContract, context: { tempId: string } | undefined) => {
			console.log(error);
			dispatch(deleteTodosAssignee(context?.tempId ?? ''));

			toast.show({
				title: t("An error occured, votre tâche n'a pas pû être créé.") as string,
				type: 'failed',
			});
		},
		onSuccess: (data: TodosContract, _: TodosContract, context: { tempId: string }) => {
			dispatch(updateTodo({ todoId: context.tempId, newTodo: data }));
			// dispatch(updateTodoFromProject({ todoId: context.tempId, newTodo: data }));
			dispatch(getTodosProject(data.projectId));
		},
	});
};

export const useUpdateTodoMutation = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	return useMutation({
		mutationFn: async ({ id, newTodo }: TodoMutation) => {
			return TodosAPI.updateTodo(newTodo, id);
		},
		onMutate: async ({ id, newTodo, oldTodo, updateFn }: TodoMutation) => {
			dispatch(updateFn({ todoId: id, newTodo }));

			return { oldTodo };
		},
		onError: (error: unknown, data: TodoMutation, context: { oldTodo: TodosContract } | undefined) => {
			console.log(error);
			dispatch(data.updateFn({ todoId: data.id, newTodo: context?.oldTodo ?? data.newTodo }));

			toast.show({
				title: t('An error occured') as string,
				type: 'failed',
			});
		},
	});
};
