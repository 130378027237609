import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CompanyContract, UserDBContract } from '../../../store/settings/type';
import styles from './fivedays-companies.modal.module.scss';
import { UserStatus } from '../../../store/user/type';
import { DateTimeFormat, getDateFormatL } from '../../../shared/utils/date.util';
import { IconClose, IconDelete, IconEdit, IconMore, IconSend, IconSimplePlus } from '../../../shared/utils/icon';
import classNames from 'classnames/bind';
import {
	deleteCompany,
	deleteCompanyState,
	getAllCompanies,
	revokeUser,
	updateCompanyDomain,
	updateCompanyDomains,
} from '../../../store/settings/slice';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import SettingsUserUpdate from '../../settings/components/user-management/settings-user-update';
import DeleteConfirmBox from '../../../shared/utils/delete-box';
import FivedaysUserInvite from './fivedays-invite';
import FivedaysUserEdit from './fivedays-edit';
import { BillingPlan } from '../../../store/billing/type';

const cx = classNames.bind(styles);

interface Props {
	close: (id: string) => void;
	companyId: string;
}

const FivedaysCompaniesModal: React.FC<Props> = ({ close, companyId }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { companies } = useAppSelector((state) => state.settings);

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [openOptions, setOpenOptions] = React.useState<number | undefined>(undefined);
	const [userToUpdate, setUserToUpdate] = React.useState<UserDBContract | undefined>(undefined);
	const [companyToDelete, setCompanyToDelete] = React.useState<CompanyContract | undefined>(undefined);
	const [openUpdate, setOpenUpdate] = React.useState(false);
	const [openInvite, setOpenInvite] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

	const [animation, setAnimation] = React.useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			close('');
			setAnimation(false);
		}, 300);
	};

	const handleOpenConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleOptions = (index: number) => {
		setOpenOptions((prev) => (prev === index ? undefined : index));
	};

	const handleUserUpdateClick = (userTU: UserDBContract, index: number) => {
		setUserToUpdate(userTU);
		handleOptions(index);
	};

	const handleUpdate = (index: number) => {
		handleOptions(index);
		setOpenUpdate(!openUpdate);
	};

	const handleInvite = () => {
		setOpenInvite(!openInvite);
	};

	const handleEdit = () => {
		setOpenEdit(!openEdit);
	};

	const handleUpdateModal = () => {
		setOpenUpdate(!openUpdate);
	};

	const handleRevokeUser = () => {
		user &&
			userToUpdate &&
			dispatch(revokeUser(userToUpdate.id)).then(() => {
				setOpenConfirmBox(!openConfirmBox);
				dispatch(getAllCompanies());

				toast.show({
					id: user?.graphUserId,
					title: t('User delete success') as string,
					duration: 10000,
					type: 'success',
				});
			});
	};

	const handleDelete = () => {
		if (companyToDelete) {
			dispatch(deleteCompany(companyToDelete.id)).then(async () => {
				await dispatch(deleteCompanyState(companyToDelete));
				setCompanyToDelete(undefined);
				closeModal();
			});
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenOptions(undefined);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	const [addDomain, setAddDomain] = React.useState(false);
	const [domainAdd, setDomainAdd] = React.useState('');

	const handleAddDomain = () => {
		setAddDomain(!addDomain);
	};

	const addANewDomain = async () => {
		const company = companies?.find((comp) => comp.id === companyId);
		if (company && domainAdd !== '') {
			const newDom = domainAdd;

			const domainSet = [...company.domain];
			domainSet.push(newDom);

			const body = {
				id: companyId,
				domains: domainSet,
			};

			setDomainAdd('');
			setAddDomain(false);
			dispatch(updateCompanyDomain(body));

			await dispatch(updateCompanyDomains(body)).then(() => {});
		}
	};

	const deleteDomain = async (domain: string) => {
		const company = companies?.find((comp) => comp.id === companyId);
		if (company) {
			const domainSet = company.domain;
			const newDomains = domainSet.filter((dom) => dom !== domain);

			const body = {
				id: companyId,
				domains: newDomains,
			};

			dispatch(updateCompanyDomain(body));

			await dispatch(updateCompanyDomains(body)).then(() => {});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				{companies &&
					companies
						.filter((company) => company.id === companyId)
						.map((company) => (
							<div key={company.id}>
								<div className={styles.titleWrapper}>
									<div className={styles.flex}>
										<h3>{t(company.name)}</h3>
										<div className={styles.wrapperContainer}>
											<button className={styles.invite} onClick={() => handleInvite()}>
												<div className={styles.icon}>
													<IconSimplePlus />
												</div>
												<span onClick={() => handleInvite()}>{t('Invite user')}</span>
											</button>
											<button className={styles.invite} onClick={() => handleEdit()}>
												<span>
													<IconEdit />
												</span>
											</button>
											<button
												className={styles.invite}
												onClick={() => {
													setCompanyToDelete(company);
													handleOpenConfirmBox();
												}}>
												<span>
													<IconDelete />
												</span>
											</button>
										</div>
									</div>

									<div className={styles.closeButton}>
										<div
											className={styles.icon}
											onClick={() => {
												closeModal();
											}}>
											<IconClose />
										</div>
									</div>
								</div>
								<div className={styles.overview}>
									<table>
										<thead>
											<tr>
												<th>{t('Name')}</th>
												<th>{t('Country')}</th>
												<th>{t('Plan')}</th>
												<th>{t('End date free trial')}</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>{company.name}</td>
												<td>{company.country}</td>
												<td>
													{company?.billingPlan?.plan}

													{company.billingPlan.plan === BillingPlan.TRIAL && (
														<span>
															({getDateFormatL(company.billingPlan.endDateTrial)})
														</span>
													)}
													{company.billingPlan.plan === BillingPlan.PERUSER && (
														<span>
															({company.users?.length} /{' '}
															{company.billingPlan.usersForPlan})
														</span>
													)}
												</td>
												<td>{DateTimeFormat(company?.billingPlan?.endDateTrial)}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className={styles.cat}>
									<div className={styles.titleCat}>
										<span>{t('Users')}</span>
										<span className={styles.number}> {company.users?.length}</span>
									</div>
								</div>
								<div className={styles.overview}>
									<table>
										<thead>
											<tr>
												<th>{t('user name')}</th>
												<th>{t('Email address')}</th>
												<th>{t('Last connection')}</th>
												<th>{t('Status')}</th>
												<th>{t('Role with the Organisation')}</th>
												<th>{t('Role')}</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{company?.users?.map((userForCompany: UserDBContract, index: number) => {
												return (
													<tr key={index}>
														<td className={styles.displayName}>{userForCompany.name}</td>
														<td>{userForCompany.username}</td>
														<td>{DateTimeFormat(userForCompany.lastConnection)}</td>
														<td
															className={cx(styles.statut, {
																active: userForCompany.status === UserStatus.ACTIVE,
																pending: userForCompany.status === UserStatus.PENDING,
															})}>
															{userForCompany.status}
														</td>
														<td>{userForCompany.jobTitle}</td>
														<td>{userForCompany.role}</td>
														<td className={styles.moreCol}>
															<span
																className={styles.more}
																onClick={() =>
																	handleUserUpdateClick(userForCompany, index)
																}>
																<IconMore />
															</span>
															{openOptions === index &&
																userToUpdate === userForCompany && (
																	<div className={styles.options} ref={modalRef}>
																		<div
																			className={styles.edit}
																			onClick={() => {
																				handleUpdate(index);
																			}}>
																			<IconEdit /> <span>{t('Edit user')}</span>
																		</div>
																		{user?.email !== userToUpdate.username && (
																			<div
																				className={styles.remove}
																				onClick={() => {
																					setOpenConfirmBox(!openConfirmBox);
																					handleOptions(index);
																				}}>
																				<IconClose />{' '}
																				<span>{t('Remove access')}</span>
																			</div>
																		)}
																	</div>
																)}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
								<div>
									<div className={styles.cat}>
										<div className={styles.titleCat}>
											<span>{t('Domain')}</span>
											<span className={styles.number}> {company.domain?.length}</span>
										</div>
									</div>
									<div>
										{company?.domain?.map((dom) => (
											<div className={styles.domain} key={dom}>
												<span>{dom}</span>

												<div className={styles.close} onClick={() => deleteDomain(dom)}>
													<IconDelete />
												</div>
											</div>
										))}
										<br />

										{addDomain && (
											<div className={styles.form}>
												<input onChange={(e) => setDomainAdd(e.target.value)} />
												<div className={styles.icon} onClick={() => addANewDomain()}>
													<IconSend />
												</div>
												<div className={styles.icon} onClick={() => handleAddDomain()}>
													<IconClose />
												</div>
											</div>
										)}

										{!addDomain && <button onClick={() => handleAddDomain()}>{t('Add')}</button>}
									</div>
								</div>
							</div>
						))}
				{openUpdate && userToUpdate && (
					<SettingsUserUpdate close={handleUpdateModal} userToUpdate={userToUpdate} />
				)}

				{openConfirmBox && userToUpdate && (
					<DeleteConfirmBox
						handleDelete={handleRevokeUser}
						handleConfirmBox={handleOpenConfirmBox}
						message='Are you sure you want to revoke this user?'
					/>
				)}

				{openConfirmBox && companyToDelete && (
					<DeleteConfirmBox
						handleDelete={handleDelete}
						handleConfirmBox={handleOpenConfirmBox}
						message='Are you sure you want to delete this company?'
					/>
				)}

				{openInvite && <FivedaysUserInvite close={handleInvite} companyId={companyId} />}
				{openEdit && (
					<FivedaysUserEdit
						close={handleEdit}
						companyId={companyId}
						company={companies?.find((company) => company.id === companyId)}
					/>
				)}
			</div>
		</div>
	);
};

export default FivedaysCompaniesModal;
