import React, { MouseEvent, useRef, useState } from 'react';
import ModalLayout from '../../../shared/components/modalLayout/modal-layout.component';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import useClickOutside from '../../../shared/hooks/use-click-outside.hook';
import { getStartOfDayDate, getDayPastOneDay, getStartOfDay, getDateFormatL } from '../../../shared/utils/date.util';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMeetings, getMeetingsForSelectOption, setSelectedMeeting } from '../../../store/meetings/slice';
import styles from '../todos.module.scss';
import { TodosContract, UpdateTodo } from '../../../store/todos/type';
import { useNavigate } from 'react-router-dom';
import Input, { Items } from '../../../shared/components/input/input.component';
import { SectionType, useUpdateTodoMutation } from '../../../shared/hooks/use-todos-mutations.hook';
import { IconClose } from '../../../shared/utils/icon';
import { PayloadAction } from '@reduxjs/toolkit';
import { MeetingContract } from '../../../store/meetings/types';

interface Props {
	todo: TodosContract;
	sectionType: SectionType;
	updateFn: (value: UpdateTodo) => PayloadAction<UpdateTodo>;
}

const MeetingCell: React.FC<Props> = ({ todo, sectionType, updateFn }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { meetingsOption } = useAppSelector((state) => state.meetings);
	const [openMeetingMenu, setOpenMeetingMenu] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const updateTodoMeetingMutation = useUpdateTodoMutation();

	const meetingModalRef = useRef<HTMLDivElement | null>(null);

	useClickOutside(meetingModalRef, () => setOpenMeetingMenu(false));

	let timeoutId: any;

	const onChangeHandlerMeeting = (filter: string) => {
		const clearFilter = filter.replace(/\(\d{2}\/\d{2}\/\d{4}\)|[éè\\/]/g, '');
		setIsLoading(true);

		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(clearFilter));
			setIsLoading(false);
		}, 200);
	};

	const handleRemoveMeeting = (e: MouseEvent) => {
		e.stopPropagation();

		const body: any = {
			...todo,
			graphiCalUId: null,
			meetingGraphId: null,
			meetingName: null,
			meetingStartDate: null,
		};

		updateTodoMeetingMutation.mutate({ id: todo.id, newTodo: body, oldTodo: todo, updateFn });
	};

	const handleChangeMeeting = (item: Items | undefined) => {
		const selectedMeeting = meetingsOption?.find((itemMeeting) => itemMeeting.iCalUId === item?.id);

		const body: any = {
			...todo,
			graphiCalUId: selectedMeeting?.iCalUId,
			meetingGraphId: selectedMeeting?.graphEventId,
			meetingName: selectedMeeting?.subject,
			meetingStartDate: selectedMeeting?.start,
		};

		updateTodoMeetingMutation.mutate({ id: todo.id, newTodo: body, oldTodo: todo, updateFn });

		setOpenMeetingMenu(false);
	};

	return (
		<div ref={meetingModalRef} className={styles.todoRowColumn}>
			<div className={styles.todoRowColumnContent} onClick={() => setOpenMeetingMenu(true)}>
				<span
					className={styles.linkMeeting}
					onClick={(e) => {
						e.stopPropagation();
						const startDate = getStartOfDayDate(new Date(todo.meetingStartDate));
						const endDate = getDayPastOneDay(new Date(todo.meetingStartDate));
						const dateRange = { startDate, endDate };
						dispatch(getMeetings(dateRange)).then((res) => {
							const meetingsSelect = (res.payload as MeetingContract[]).find(
								(m) => m.iCalUId === todo.graphiCalUId,
							);
							dispatch(setSelectedMeeting(meetingsSelect));
							const params: any = {};
							params[RouteSearchParams.Date] = getStartOfDay(todo.meetingStartDate);
							params[RouteSearchParams.MeetingId] = todo.graphiCalUId;
							navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
						});
					}}>
					{todo.meetingName}
				</span>
				{todo.meetingName && (
					<div className={styles.removeMeetingOrProject} onClick={handleRemoveMeeting}>
						<IconClose />
					</div>
				)}
			</div>
			{openMeetingMenu && (
				<ModalLayout top='0'>
					<div className={styles.meetingModal}>
						<Input
							placeHolder=''
							defaultValue={{
								title: todo.meetingName ?? null,
							}}
							renderPlaceHolder={false}
							isLoading={isLoading}
							getValue={handleChangeMeeting}
							onChange={(e) => onChangeHandlerMeeting(e.currentTarget.value)}
							renderModal={meetingsOption?.map((itemMeeting) => ({
								id: itemMeeting.iCalUId,
								title: `${itemMeeting.subject} (${getDateFormatL(itemMeeting.createdDateTime)})`,
							}))}
						/>
					</div>
				</ModalLayout>
			)}
		</div>
	);
};

export default MeetingCell;
