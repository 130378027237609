/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import styles from './header.module.scss';
import { useMsal } from '@azure/msal-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Persona, PersonaInitialsColor, PersonaSize } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAllUsersJobList, getCurrentUserContacts } from '../../../store/user/slice';
import { IconNotifications, IconSettings, IconTickets } from '../../utils/icon';
import { getMeetingsForSelectOption } from '../../../store/meetings/slice';
import { getNotifications } from '../../../store/notifications/slice';
import HeaderNotificationsModal from './components/header-notifications.modal';
import { AuthenticationProvider, UserRole, UserRoleFivedays } from '../../../store/user/type';
import { RouteUrls } from '../../../routes/routes-config';
import { getAllNote } from '../../../store/notes/slice';
import { getProjects } from '../../../store/project/slice';
import { getAvatarForCurrentUser } from '../../../store/avatars/slice';
import { AvatarData } from '../../../store/avatars/type';
import { getBillingInfoForAUser } from '../../../store/billing/slice';
import { BillingPlan } from '../../../store/billing/type';
import { getDateDifference } from '../../utils/date.util';
import Logo from '../../../../images/logo/Logo_Fivedays.png';
import TooltipIcon from '../tooltip/tooltip-icon.component';
import { useAuth } from 'react-oidc-context';
import { useDesktopNotification } from '../../hooks/use-desktop-notifications';
import { NotificationsContract } from '../../../store/notifications/type';

declare let BrevoConversations: {
	(methodName: string, ...args: any[]): any;
};

// declare const pendo: any;
// hello

const Header = () => {
	const { instance } = useMsal();
	const auth = useAuth();
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	const { t } = useTranslation();
	const [avatar, setAvatar] = React.useState<string | undefined>();
	const { user } = useAppSelector((state) => state.user);
	const { billingInfo } = useAppSelector((state) => state.billing);
	const { notifications } = useAppSelector((state) => state.notifications);
	const [newNotifications, setNewNotifications] = useState<NotificationsContract[]>([]);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const currentPath = window.location.pathname;
	const MeetingPagePath = RouteUrls.Meetings;
	const NotesPagePath = RouteUrls.Notes;
	const ProjectPagePath = RouteUrls.Project;

	const renderMeetings = currentPath === MeetingPagePath;
	const renderNotes = currentPath === NotesPagePath;
	const renderProject = currentPath === ProjectPagePath;

	React.useEffect(() => {
		if (user) {
			dispatch(getCurrentUserContacts('a'));
			dispatch(getMeetingsForSelectOption('a'));
			dispatch(getNotifications());
			dispatch(getAllNote());
			dispatch(getProjects());
			dispatch(getBillingInfoForAUser());
			dispatch(getAllUsersJobList());
		}
	}, [user]);

	useEffect(() => {
		if (notifications) {
			const newNotifs = notifications.filter((notif) => notif.isNew === true);
			setNewNotifications(newNotifs);
		}
	}, [notifications]);

	useEffect(() => {
		if (user) {
			if (user.provider === AuthenticationProvider.Microsoft) {
				dispatch(getAvatarForCurrentUser(user.email)).then((response) => {
					const avatarData = response.payload as AvatarData;
					if (response.payload !== undefined) {
						setAvatar(avatarData.avatar);
					} else {
						setAvatar(undefined);
					}
				});
			} else if (user.provider === AuthenticationProvider.Google) {
				setAvatar(user.picture);
			}
		}
	}, [user]);

	useDesktopNotification(notifications);

	useEffect(() => {
		const intervalId = setInterval(() => dispatch(getNotifications()), 20000);
		return () => clearInterval(intervalId);
	}, []);

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setIsDropdownVisible(false);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setIsDropdownVisible]);

	// --- notifications

	const [openNotif, setOpenNotif] = React.useState(false);

	const modalRefNotif = React.useRef<HTMLDivElement>(null);

	const handleOpenNotif = () => {
		setOpenNotif(!openNotif);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefNotif.current && !modalRefNotif.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleOpenNotif();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleOpenNotif]);

	const handleSupport = () => {
		const existingScript = document.querySelector(
			'script[src="https://conversations-widget.brevo.com/brevo-conversations.js"]',
		);

		if (existingScript) {
			const displayNameParts = user?.displayName.split(' ');
			const firstName = displayNameParts?.[0] || '';
			const lastName = displayNameParts?.slice(1).join(' ') || '';

			BrevoConversations('updateIntegrationData', {
				email: user?.email,
				firstName,
				lastName,
			});

			BrevoConversations('setButtonPosition', 'rb');

			BrevoConversations('openChat');
		} else {
			(function (d: any, w: any, c: any) {
				w.BrevoConversationsID = '656ee886f438063d0402d1d1';
				w[c] =
					w[c] ||
					function () {
						(w[c].q = w[c].q || []).push(arguments);
					};
				const s = d.createElement('script');
				s.async = true;
				s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
				if (d.head) d.head.appendChild(s);
			})(document, window, 'BrevoConversations');

			const displayNameParts = user?.displayName.split(' ');
			const firstName = displayNameParts?.[0] || '';
			const lastName = displayNameParts?.slice(1).join(' ') || '';

			BrevoConversations('updateIntegrationData', {
				email: user?.email,
				firstName,
				lastName,
			});

			BrevoConversations('setButtonPosition', 'rb');

			BrevoConversations('openChat');
		}
	};

	// ---

	// useEffect(() => {
	// 	if (user) {
	// 		pendo.initialize({
	// 			visitor: {
	// 				id: user.userId,
	// 				email: user.userName,
	// 				full_name: user.displayName,
	// 				role: user.role,
	// 			},
	// 			account: {
	// 				id: '<account-id-goes-here>', // Highly recommended, required if using Pendo Feedback
	// 				// name:         // Optional
	// 				// is_paying:    // Recommended if using Pendo Feedback
	// 				// monthly_value:// Recommended if using Pendo Feedback
	// 				// planLevel:    // Optional
	// 				// planPrice:    // Optional
	// 				// creationDate: // Optional
	// 				// You can add any additional account level key-values here,
	// 				// as long as it's not one of the above reserved names.
	// 			},
	// 		});
	// 	}
	// }, [user]);

	return (
		<>
			<div
				className={styles.header}
				style={{
					paddingRight: window.api && window.api.isElectron ? '135px' : '',
					height: window.api && window.api.isElectron ? '46px' : '',
				}}>
				<div className={styles.leftPart}>
					{!renderMeetings && !renderNotes && !renderProject && <span></span>}

					{renderMeetings && <div className={styles.meetings}></div>}

					{renderNotes && <div className={styles.meetings}></div>}

					{renderProject && <div className={styles.meetings}></div>}

					{user && user.role === UserRole.ADMIN && billingInfo && billingInfo.plan === BillingPlan.TRIAL && (
						<div className={styles.trialPlan}>
							<div>
								{t('Your trial plan expire in')}{' '}
								<span className={styles.bold}>
									{getDateDifference(new Date(billingInfo.endDateTrial))} {t('days')}.
								</span>
							</div>
							{/* <a className={styles.linkTrial}>See plans</a>
							<a className={styles.linkTrial}>Invite people</a> */}
						</div>
					)}
				</div>

				<div id='navbarBtn' className={styles.rightPart}>
					<div
						className={styles.sidebarBottom}
						data-tooltip-id={'Settings 5Days'}
						data-tooltip-content={t('Settings 5Days').toString()}>
						{user?.roleFivedays === UserRoleFivedays.SUPERADMIN && (
							<NavLink to={RouteUrls.SettingFivedays} className={styles.icon}>
								<div className={styles.logo}>
									<img src={Logo} alt='Logo Fivedays' />
								</div>
							</NavLink>
						)}
					</div>

					<div
						className={styles.sidebarBottom}
						data-tooltip-id={'Settings'}
						data-tooltip-content={t('Settings').toString()}>
						{user?.role === UserRole.ADMIN && (
							<NavLink to={RouteUrls.Settings} className={styles.icon}>
								<IconSettings />
							</NavLink>
						)}
					</div>

					<div>
						<div
							className={styles.iconNotif}
							onClick={() => handleOpenNotif()}
							data-tooltip-id={'Notifications'}
							data-tooltip-content={t('Notifications').toString()}>
							{newNotifications && newNotifications.length > 0 && (
								<div className={styles.nbrNotif}>{newNotifications.length}</div>
							)}
							<IconNotifications />
						</div>
						{openNotif && (
							<div ref={modalRefNotif}>
								<HeaderNotificationsModal close={handleOpenNotif} />
							</div>
						)}
					</div>

					<div
						className={styles.icon}
						style={{ cursor: 'pointer' }}
						onClick={() => handleSupport()}
						data-tooltip-id={'Support'}
						data-tooltip-content={t('Support').toString()}>
						<IconTickets />
					</div>

					<div className={styles.dropDownAccount}>
						<div
							className={styles.avatar}
							onClick={() => {
								if (!isDropdownVisible) {
									setIsDropdownVisible(true);
								}
							}}>
							<div className='avatar'>
								<Persona
									text={user?.displayName}
									secondaryText={user?.email}
									size={PersonaSize.size24}
									initialsColor={PersonaInitialsColor.green}
									hidePersonaDetails={true}
									imageUrl={avatar}
								/>
							</div>
							{window.api && !window.api.isElectron && <p>{user?.displayName}</p>}
						</div>

						{isDropdownVisible && (
							<div className={styles.container} ref={modalRef}>
								<div className={styles.infoAccount}>
									<Persona
										text={user?.displayName}
										secondaryText={user?.email}
										size={PersonaSize.size56}
										initialsColor={PersonaInitialsColor.green}
										hidePersonaDetails={true}
										imageUrl={avatar}
									/>
									<div className={styles.userInfos}>
										<span className={styles.name}>{user?.displayName}</span>
										<span className={styles.mail}>{user?.email}</span>
										<span
											onClick={() => {
												navigate('/account');
												setIsDropdownVisible(!isDropdownVisible);
											}}
											className={styles.viewAccount}>
											{t('View account')}
										</span>
									</div>
								</div>
								<span
									className={styles.signOut}
									onClick={() => {
										if (user) {
											if (user.provider === AuthenticationProvider.Microsoft) {
												instance.logoutRedirect({ postLogoutRedirectUri: '/' });
											} else if (user.provider === AuthenticationProvider.Google) {
												auth.signoutRedirect();
											}
										}
									}}>
									{t('Sign out')}
								</span>
							</div>
						)}
					</div>
				</div>
				<TooltipIcon id={'Settings 5Days'} title={t('Settings 5Days')} placement='bottom' />
				<TooltipIcon id={'Settings'} title={t('Settings')} placement='bottom' />
				<TooltipIcon id={'Notifications'} title={t('Notifications')} placement='bottom' />
				<TooltipIcon id={'Support'} title={t('Support')} placement='bottom' />
			</div>
		</>
	);
};

export default Header;
